import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CurrentWeatherCard from '../weatherForecast/cards/currentWeatherCard.js';
import TwoWeekForecastCard from '../weatherForecast/cards/twoWeekForecastCard';
import ExtraInfo from './extraInfo';
import { Paper, Container } from "@mui/material";
import { ApiClient } from "../api/axiosClient.js";
import {Helmet} from 'react-helmet-async'

function LocationPage({onWeatherUpdate}) {

  const { continent, location } = useParams();
  const [locationData, setLocationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 440);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 440);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const instance = ApiClient.getInstance();
        const paramsUrl = `${continent}/${location}`
        const response = await instance.client.get(paramsUrl);
        setLocationData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [continent, location]);

  useEffect(() => {
    if (locationData) {
      const backgroundStyle = getBackgroundColor(
        locationData.weatherInfo.weather[0].main,
        locationData.weatherInfo.weather[0].description,
        new Date().getTime() / 1000,
        locationData.weatherInfo.sys.sunrise,
        locationData.weatherInfo.sys.sunset,
        locationData.weatherInfo.timezone
      );
      onWeatherUpdate(backgroundStyle);
    }
  }, [locationData, onWeatherUpdate]);

  const getBackgroundColor = (
    weatherCode,
    weatherDescription,
    currentTime,
    sunrise,
    sunset,
    timezoneOffset
  ) => {
    const localTime = new Date(currentTime * 1000 + timezoneOffset * 1000);
    const sunriseDate = new Date((sunrise + timezoneOffset) * 1000);
    const sunsetDate = new Date((sunset + timezoneOffset) * 1000);
    const isNightTime = localTime < sunriseDate || localTime >= sunsetDate;

    switch (weatherCode) {
      case "Clear":
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--clear))";
        } else {
          return "linear-gradient(to bottom, var(--clear), var(--text))";
        }
      case "Haze":
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--clear))";
        } else {
          return "linear-gradient(to bottom, var(--clouds), var(--rain))";
        }
      case "Clouds":
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--clouds))";
        } else {
          if (weatherDescription === 'few clouds') {
            return "linear-gradient(to bottom, var(--clear), var(--clouds))";
          }
          return "linear-gradient(to bottom, var(--clouds), var(--clouds))";
        }
      case "Rain":
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--rain))";
        } else {
          return "linear-gradient(to bottom, var(--rain), var(--text))";
        }
      case "Drizzle":
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--rain))";
        } else {
          return "linear-gradient(to bottom, var(--rain), var(--text))";
        }
      case "Snow":
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--snow))";
        } else {
          return "linear-gradient(to bottom, var(--snow), var(--text))";
        }
      default:
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--clouds))";
        } else {
          return "linear-gradient(to bottom, var(--clouds), var(--clouds))";
        }
    }
  };

  const cappedLocation = location.split('-')
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join(' ');

  return (
    <>
    <Helmet>
      <title>{`${cappedLocation} Weather | SunRadar | Weather Wiki`}</title>
      <meta name="description" content={`Get the latest weather updates, a two-week forecast, and plan your next holiday with top facts, insights and activities in ${cappedLocation}.`}/>
      <link rel="canonical" href={`https://www.weatherwiki.com/${continent}/${location}`}/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <script type="application/ld+json">
      {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "${cappedLocation} Weather",
            "description":"Get the latest weather updates, a two-week forecast, and plan your next holiday with top facts, insights and activities in ${cappedLocation}.",
            "url": "https://www.weatherwiki.com/${continent}/${location}"
          }
      `}
      </script>
    </Helmet>


      <div style={{ backgroundColor: "transparent" }}>
        <Container
          maxWidth="md"
          style={{
            margin: "10px auto",
            background: "transparent",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <div>
            <CurrentWeatherCard weatherData={locationData?.weatherInfo} loading={loading} extraInfoLocationName={locationData?.locationInfo.name}/>
           
            {!loading && locationData && (
           <ExtraInfo extraInfo={locationData.locationInfo.extraInfo} />
            )}
          
            <TwoWeekForecastCard forecastData={locationData?.locationInfo.weatherInfo} currentWeather={locationData?.weatherInfo}
          />
          </div>
        </Container>
      </div>
    </>
  );
}

export default LocationPage;
