import axios from "axios"

const createClient = (baseUrl) => {
    const apiClient = axios.create({
        timeout: 60000,
        headers: {
            'Content-Type': 'application/json'
        },
        baseURL: baseUrl,
    })

    apiClient.interceptors.request.use((axiosConfig) => {
        return axiosConfig;
    });

    const retryInterceptor = (error) => {
        const { config, response } = error;
        const originalConfig = error.config;
        if (response && response.status === 500 && !originalConfig._retry) {
            originalConfig._retry = true;
            return apiClient(config)
        }
        if (response && response.status === 400 && 
            config.url === 'getlocation' && !originalConfig._retry) {
            originalConfig._retry = true;
            const errorMessage = response.data.error || '';
            if (errorMessage.includes('Cannot get lon and lat')) {
                const newConfig = { ...config, url: '/getlocationnoip' };
                return apiClient(newConfig); 
            }
        }
        return Promise.reject(error);
    };

    apiClient.interceptors.response.use(
        (response) => response,
        retryInterceptor   
    );

    return apiClient
}

export class ApiClient {

    static instance = null

    constructor(baseUrl) {
        this.client = createClient(baseUrl)
    }

    static getInstance() {
        if (ApiClient.instance) {
            return ApiClient.instance 
        }

        //for prod
    const baseUrl = window.location.origin
        
        //for local
   //  const baseUrl = "http://localhost:3001/"

        const instance = new this(baseUrl)
        Object.freeze(instance)
        ApiClient.instance = instance
        return ApiClient.instance 
    }
}