import { React, lazy, Suspense } from "react";
import WeatherCard from "./weatherCard.js";
import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Modal, Button, Box, CircularProgress, Paper, Icon } from "@mui/material";
import ReactSlickSlider from "react-slick";
import Slider from "@mui/material/Slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../App.css";
import { ApiClient } from "../api/axiosClient.js";
import TuneIcon from "@mui/icons-material/Tune";
import { GiPalmTree, GiModernCity, GiMountainCave } from "react-icons/gi";
import Tagline from "./tagline.js";
import { useTemperatureUnit } from '../TemperatureUnitContext';
import { kelvinToUnit } from './customHooks.js';
import {Helmet} from 'react-helmet-async'
const SliderWrapper = lazy(() => import('./sliderWrapper')); // adjust the path as necessary



function Sunradar() {
  const [regionData, setRegionData] = useState({
    global: [],
    asia: [],
    europe: [],
    northAmerica: [],
    southAmerica: [],
    australia: [],
    africa: []
  });
  const [clickedRegion, setClickedRegion] = useState("Europe");
  const [clickedType, setClickedType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    city: false,
    coastal: false,
    mountains: false,
  });
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 440);

  const [preferredtemp, setPreferredtemp] = useState(null);
  const [preferredtempFilter, setPreferredtempFilter] = useState(null);

  const { unit, toggleUnit } = useTemperatureUnit();


  useEffect(() => {
   if(unit === 'degF') {
    setPreferredtemp([64, 100]);
    setPreferredtempFilter([64, 100]);
   } else {
    setPreferredtemp([18, 38]);
    setPreferredtempFilter([18, 38]);
   }
  }, [unit, toggleUnit]);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 440);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    setLoading(true);

    const fetchSunRadarLocations = async () => {
      try {
        const instance = ApiClient.getInstance();

        const [global, europe, asia, northAmerica, southAmerica, australia, africa] = await Promise.all([
          instance.client.get("global"),
          instance.client.get("europe"),
          instance.client.get("asia"),
          instance.client.get("northamerica"),
          instance.client.get("southamerica"),
          instance.client.get("australia"),
          instance.client.get("africa"),
        ]);

        setRegionData({
          global: global.data,
          europe: europe.data,
          asia: asia.data,
          southamerica: southAmerica.data,
          northamerica: northAmerica.data,
          australia: australia.data,
          africa: africa.data
        });
        } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchSunRadarLocations();
  }, []);

  const handleRegionClick = (region) => {
    setFilters({
      city: false,
      coastal: false,
      mountains: false,
    });
  
    setClickedType(null);
  
    // Check if the clicked region is the same as the currently clicked region
    if (region === clickedRegion) {
      setClickedRegion(null); // Unselect the region if it's the same
    } else {
      setClickedRegion(region); // Set the new region otherwise
    }
  };
  

  const handleTypeClick = (type) => {
    setClickedType(type);
  };

  const handleApplyFilters = () => {
    clickedType &&
      setFilters({
        [clickedType.toLowerCase()]: true,
      });
    setPreferredtempFilter(preferredtemp)
    setOpen(false);
  };
  const handleResetFilters = () => {
    setFilters({
      city: false,
      coastal: false,
      mountains: false,
    });
    setClickedType(null);
    setPreferredtemp([18, 38])
    setPreferredtempFilter([18, 38])
  };

  const handleChange = (event, newValue) => {
    setPreferredtemp(newValue);
  };

  const typeToIcon = {
    City: (
      <GiModernCity
        size="25"
        style={{ padding: '2px 0px 0px 0px', opacity: "0.95", color: "gray" }}
      />
    ),
    Coastal: (
      <GiPalmTree
        size="25"
        style={{ padding: '2px 0px 0px 0px', opacity: "0.95", color: "gray" }}
      />
    ),
    Mountains: (
      <GiMountainCave
        size="25"
        style={{ padding: '2px 0px 0px 0px', opacity: "0.95", color: "gray" }}
      />
    ),
  };


  const filterLocation = (location) => {
    const isCity =
      filters.city && location.type === "city";
    const isCoastal =
      filters.coastal && location.type === "coastal";
    const isMountain =
      filters.mountains &&
      location.type === "mountains";

    const averageDayTemp = kelvinToUnit(unit, location.avgTemp);
    
    const isWithinPreferredTemp =
      averageDayTemp>= preferredtempFilter[0] &&
      averageDayTemp <= preferredtempFilter[1];
    return (
      (!filters.city &&
        !filters.coastal &&
        !filters.mountains && isWithinPreferredTemp) ||
      ((isCity ||
        isCoastal ||
        isMountain) && isWithinPreferredTemp)
    )
  }

  return (
    <>
    <Helmet>
      <title>SunRadar | Weather Wiki</title>
      <meta name="description" content="1000+ holiday destinations, ranked by sunshine. So you always know where it's sunny!"/>
      <link rel="canonical" href="https://www.weatherwiki.com/sunradar"/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <script type="application/ld+json">
      {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "SunRadar",
            "description": "1000+ holiday destinations, ranked by sunshine.",
            "url": "https://www.weatherwiki.com/sunradar"
          }
      `}
      </script>
    </Helmet>

      <Container
        maxWidth="md"
        style={{
          margin: "10px auto",
          background: "transparent",
          padding: "10px",
          borderRadius: "10px",
          height: loading ? "1600px" : 'auto',  // Conditional height based on loading state
        }}
      >
        <Paper
          elevation={3}
          style={{
            backgroundColor: 'white',
            borderRadius: "20px",
            marginBottom: isMobile ? '4px' : '16px',
            marginTop: isMobile ? '-12px' : '0px',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            height : loading ? (isMobile ? "175px" : "230px") : 'auto',
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%", 
              paddingRight: "16px", 
            }}
          >
            <Typography
              variant={isMobile ? 'h7' : 'h6'}
              style={{
                // fontSize: "20px",
                fontWeight: "bold",
                textAlign: isMobile ? "left" : "center",
                marginTop: isMobile ? '8px' : '16px',
                color: "var(--secondary)",
                flexGrow: 1,
                marginLeft: isMobile ? "20px" : "100px",
                
              }}
            >
              Select by Region
            </Typography>


            <Button
              style={{
                fontSize: isMobile ? '12px' : '16px',
                width: isMobile ? "30px" : "40px",
                height: isMobile ? "30px" : "40px",
                color: "var(--secondary)",
                // marginLeft: "auto",
                marginTop: isMobile ? '8px' : '16px',
                borderRadius: "100px",
                padding: isMobile ? '0px 0px 0px 8px' : '4px 0px 4px 8px',
                textTransform: "none", 
                backgroundColor: "white", 
                border: '1px solid var(--secondary)'
              }}
              startIcon={<TuneIcon style={{color: 'var(--secondary)', fontSize: '20px'}}/>}
              color= 'primary' 
              // variant="contained"
              onClick={handleOpen}
            >  
            </Button>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="filter-modal"
              aria-describedby="filter-options"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: '85%',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  marginTop: '-25px',
                  marginLeft: isMobile ? '0px' : '-16px',
                  width: '105%',
                  maxWidth: '820px',
                  bgcolor: "background.paper",
                  borderRadius: 4,
                  boxShadow: 24,
                  p: 2,
                  
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: 'center',
                    marginBottom: '-6px'
                  }}
                >
                  {["City", "Coastal", "Mountains"].map((type) => (
                    <Paper
                      key={type}
                      elevation={3}
                      onClick={() => handleTypeClick(type)}
                      style={{
                        marginTop: '-10px',
                        backgroundColor: "white",
                        borderRadius: "20px",
                        margin: "2px",
                        gap: '0px',
                        flex: 1,
                        display: "flex",
                        Width: '100%',
                        flexDirection: "column",
                        alignItems: "center",
                        overflow: "hidden",
                        padding: '0px 2px 0px 4px',
                        border:
                          clickedType === type ? "1px solid gray" : "none",
                      }}
                    >
                      <span>
                        <Typography
                          style={{
                            fontSize: 'clamp(16px, 1.5vw, 24px)',
                            flexWrap: "nowrap",
                            // fontWeight: "bold",
                            textAlign: "center",
                            marginTop: "auto",
                            paddingBottom: "2px",
                            color: "gray",
                          }}
                        >
                          {type} 
                          <Icon
                            style={{marginLeft: '4px'}}>
                          {typeToIcon[type]}
                          </Icon>
                        </Typography>
                        
                      </span>
                    </Paper>
                  ))}
                </div>
                <Box>
                  <div
                    style={{
                      marginTop: "2px",
                      alignItems: "center",
                      marginBottom: "2px",
                    }}
                  >
                    <h4 style={{ color: "gray", marginBottom: "40px", textAlign: isMobile ? 'center' : 'left' }}>
                      Choose Temperature Range
                    </h4>
                  </div>
                  <Slider
                    getAriaLabel={() => "Temperature range"}
                    value={preferredtemp}
                    onChange={handleChange}
                    valueLabelDisplay="on"
                    
                    max={unit === 'degC' ? 40 : 100}
                    min={unit === 'degC' ? 0 : 32}
                    style={{ color: "gray", height: '1px' }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 0.5,
                  }}
                >
                  <Button
                    style={{
                      fontSize: "15px",
                      color: "var(--text)",
                      borderRadius: "20px",
                      textTransform: "none",
                      backgroundColor: "gray",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={handleResetFilters}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      fontSize: "15px",
                      color: "var(--text)",
                      marginLeft: "auto",
                      borderRadius: "20px",
                      textTransform: "none",
                      backgroundColor: "gray",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </Button>
                </Box>
              </Box>
            </Modal>
          </div>
          <Suspense fallback={<div style={{
            minHeight: '158px', 
            width: '100%',
          }}></div>}>
              <SliderWrapper isMobile={isMobile} handleRegionClick={handleRegionClick} clickedRegion={clickedRegion}></SliderWrapper>
          </Suspense>

        </Paper>

        <Tagline/>


        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginTop: "10px" }}
            >
              Fetching weather...
            </Typography>
          </div>
        ) : (
          <>
            {clickedRegion ? (
             <div>
             {Object.entries(regionData).map(([region, locations]) =>
               clickedRegion && region === clickedRegion.toLowerCase() ? (
                 <div key={region}>
                   {(() => {
                     const processedLocations = locations
                       .filter(filterLocation)
                       .sort((a, b) => b.score - a.score)
                       .slice(0, 30);
           
                     if (processedLocations.length > 0) {
                       return processedLocations.map((location, index) => (
                         <WeatherCard
                           key={index}
                           weatherData={location}
                           clickedRegion={clickedRegion}
                         />
                       ));
                     } else {
                       return  <Paper style={{ borderRadius: '20px', padding: '10px', marginBottom: isMobile ? '4px' : '16px' }}> {/* Add padding to the Paper component */}
                       <div style={{ textAlign: 'center' }}> {/* Center the content */}
                           <Typography
                             variant={isMobile ? 'h7' : 'h5'} 
                             style={{ color: 'gray', padding: '10px' }} 
                           >
                              There are no destinations with your desired weather filters, please adjust your search and try again!
                           </Typography>
                       </div>
                   </Paper>;
                     }
                   })()}
                 </div>
               ) : null
             )}
           </div>
           
            ) : (
              <div>
              {Object.entries(regionData).map(([region, data]) => (
                <div key={region}>
                  {region === "global" && (
                    <>
                      {(() => {
                        const filteredSortedData = data
                          .filter((location) => filterLocation(location))
                          .sort((a, b) => b.score - a.score)
                          .slice(0, 20);
                        
                        if (filteredSortedData.length > 0) {
                          return filteredSortedData.map((location, index) => (
                            <WeatherCard
                              key={index}
                              weatherData={location}
                              clickedRegion={clickedRegion}
                              style={{ backgroundColor: "var(--tertiary)" }}
                            />
                          ));
                        } else {
                          return <Paper style={{ borderRadius: '20px', padding: '10px', marginBottom: isMobile ? '4px' : '16px' }}> 
                          <div style={{ textAlign: 'center' }}> 
                              <Typography
                                variant={isMobile ? 'h7' : 'h5'}
                                style={{ color: 'gray', padding: '10px' }} 
                              >
                                  There are no destinations with your desired weather filters, please adjust your search and try again!
                              </Typography>
                          </div>
                      </Paper>;;
                        }
                      })()}
                    </>
                  )}
                </div>
              ))}
            </div>
            
            )}
          </>
        )}
        
      </Container>
    </>
  );
}

export default Sunradar;
