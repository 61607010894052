import React, { useState, useEffect } from "react";
import {
  Link,
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  Radio,
} from "@mui/material";
import { useTemperatureUnit } from "./TemperatureUnitContext"; // Ensure this path is correct
import { grey } from "@mui/material/colors";
import { ApiClient } from "./api/axiosClient.js";
import Snackbar from '@mui/material/Snackbar';

import "./App.css"; // Ensure this path is correct

const Footer = () => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const { unit, toggleUnit } = useTemperatureUnit(); // Using context for temperature unit
  const [feedbackText, setFeedbackText] = useState("");
  const handleCookieSettings = () => {
    if (window.Cookiebot) {
      window.Cookiebot.show();
    }
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleFeedbackClick = () => {
    setIsFeedbackModalOpen(true); // Show the feedback modal
  };

  const handleCloseFeedbackModal = () => {
    setIsFeedbackModalOpen(false); // Hide the feedback modal
  };

  const handleSnackbardClose = (event, reason) => {
    setSnackbarOpen(false);
  };
  const handleSubmitFeedback = async () => {
    const instance = ApiClient.getInstance();
    try {
      if(feedbackText) {
        const response = await instance.client.get("feedback", {
          params: { feedbackText: feedbackText },
        });
        setSnackbarOpen(true)
      }
      
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
    setFeedbackText("")
    setIsFeedbackModalOpen(false);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 440);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 440);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleChange = (event) => {
    toggleUnit(event.target.value); // Update the unit in context
  };

  // Modal style
  const modalStyle = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 330 : 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "20px",
  };

  return (
    <footer
      style={{
        background: "white",
        color: "grey",
        textAlign: "center",
        padding: "20px",
        width: "100%",
      }}
    >
      <div>
        <div style={{ marginTop: "0px", marginLeft: "-20px" }}>
          <Radio
            checked={unit === "degC"}
            onChange={handleChange}
            value="degC"
            name="radio-buttons"
            inputProps={{ "aria-label": "Celsius" }}
            sx={{
              color: grey[400], // The color for the unchecked state
              "&.Mui-checked": {
                color: grey[600], // The color for the checked state
              },
            }}
          />{" "}
          Celsius
          <Radio
            checked={unit === "degF"}
            onChange={handleChange}
            value="degF"
            name="radio-buttons"
            inputProps={{ "aria-label": "Fahrenheit" }}
            sx={{
              color: grey[400],
              "&.Mui-checked": {
                color: grey[600],
              },
              marginLeft: "10px",
            }}
          />{" "}
          Fahrenheit
        </div>
        <Link
          to="#"
          onClick={handleCookieSettings}
          style={{
            padding: "10px",
            fontSize: "12px",
            fontWeight: 'bold',
            marginRight: "0px",
            cursor: "pointer",
            color: "grey",
          }}
        >
          Cookie Settings
        </Link>
        <span style={{ color: "grey", margin: "0" }}>|</span>
        <Link
          to="#"
          onClick={handleFeedbackClick}
          style={{
            padding: "10px",
            fontSize: "12px",
            fontWeight: 'bold',
            cursor: "pointer",
            color: "grey",
          }}
        >
          Give us your Feedback
        </Link>
        
      </div>

      <Modal
        open={isFeedbackModalOpen}
        onClose={handleCloseFeedbackModal}
        aria-labelledby="feedback-modal-title"
        aria-describedby="feedback-modal-description"
      >
        <Box sx={modalStyle}>
          <h3 id="feedback-modal-title" style={{ color: "grey" }}>
            Please give us your Feedback
          </h3>
          <TextField
            id="feedback"
            label="Feedback"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)} // Update feedback text state
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitFeedback}
            style={{ marginTop: "10px", width: "100%", borderRadius: "20px" }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbardClose}
        message={<span style={{ color: 'grey' }}>Thank you for your feedback!</span>}
        sx={{
          '& .MuiSnackbarContent-root': {
            width: isMobile ? 100 : 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            justifyContent: 'center', // Ensures text and action items are centered
            maxWidth: 'fit-content', // Width of the Snackbar fits the content
            minWidth: 'fit-content', 
            borderRadius: "20px",
          }
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </footer>
  );
};

export default Footer;
