import React, {useState, useEffect, lazy, Suspense} from "react";
import {
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import {
    WiDaySunny,
    WiCloud,
    WiDayCloudy,
    WiCloudy,
    WiRainMix,
    WiSnow,
    WiRain,
    WiThunderstorm,
    WiFog,
    WiSleet,
    WiRaindrop,
    WiSnowflakeCold,
    WiNightClear,
    WiNightAltPartlyCloudy
   } from 'weather-icons-react';

   

import { BsSunFill } from "react-icons/bs";
import { useTemperatureUnit } from '../../TemperatureUnitContext';

const CurrentWeatherCardContents = lazy(() => import('./contents/currentWeatherCardContents.js'));

function CurrentWeatherCard({ weatherData, loading, extraInfoLocationName}) {
  const { unit } = useTemperatureUnit();
  
  const kelvinToUnit = (kelvin) => {
    if(unit === 'degC') {
      return Math.round((kelvin - 273.15).toFixed(2));
    } 
    return Math.round(((kelvin - 273.15) * 9/5 + 32).toFixed(2)); 
  };

  const getWeatherIcon = (weatherCode, currentTime, sunrise, sunset, timezoneOffset,) => {
   
    const localTime = new Date((currentTime * 1000) + (timezoneOffset * 1000));
    const sunriseDate = new Date((sunrise + timezoneOffset) * 1000);
    const sunsetDate = new Date((sunset + timezoneOffset) * 1000);
    const isDayTime = localTime >= sunriseDate && localTime < sunsetDate;
    
     switch (weatherCode) {
      case "clear sky":
        return isDayTime ? <WiDaySunny  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px", color: "yellow" }}/>
                         : <WiNightClear style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px", color: "white" }} />;

      case "few clouds":
        return isDayTime ? <img src="/cloud_sun.ico" alt="Day Sunny Overcast" style={{ width: "65px", height: "65px", padding: '0px 0px 2px 0px' }} />
                         : <WiNightAltPartlyCloudy style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px", color: "white" }} />;

      case "overcast":
      return isDayTime ? <img src="/cloud_sun.ico" alt="Sunny Overcast" style={{ width: "65px", height: "65px", padding: '0px 0px 2px 0px' }} />
                        : <WiNightAltPartlyCloudy style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px", color: "white" }} />;

      case "scattered clouds":
        return <WiCloud  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "broken clouds":
        return <WiCloudy  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "overcast clouds":
        return <WiCloudy  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
        
      case "thunderstorm with light rain":
        return <WiThunderstorm  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "thunderstorm with rain":
        return <WiThunderstorm  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "thunderstorm with heavy rain":
        return <WiThunderstorm  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "light thunderstorm":
        return <WiThunderstorm  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "thunderstorm":
        return <WiThunderstorm  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "heavy thunderstorm":
        return <WiThunderstorm style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "ragged thunderstorm":
        return <WiThunderstorm  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "thunderstorm with light drizzle":
        return <WiThunderstorm  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "thunderstorm with drizzle":
        return <WiThunderstorm  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "thunderstorm with heavy drizzle":
        return <WiThunderstorm  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;

      case "light rain":
        return <WiRainMix  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "#555555" }} />;
      case "moderate rain":
        return <WiRainMix  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "#555555" }} />;
      case "heavy intensity rain":
        return <WiRain  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "#555555" }} />;
      case "very heavy rain":
        return <WiRain  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "#555555" }} />;
      case "extreme rain":
        return <WiRain  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "#555555" }} />;
      case "freezing rain":
        return <WiSleet  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "#555555" }} />;
      case "light intensity shower rain":
        return <WiRainMix  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "#555555" }} />;
      case "shower rain":
        return <WiRainMix  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "#555555" }} />;
      case "heavy intensity shower rain":
        return <WiRain  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "#555555" }} />;
      case "ragged shower rain":
        return <WiRain  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "#555555" }} />;
        
      case "light snow":
        return <WiSnowflakeCold  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "snow":
        return <WiSnow  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "heavy snow":
        return <WiSnow  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "sleet":
        return <WiSleet  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "light shower sleet":
        return <WiSleet  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "shower sleet":
        return <WiSleet  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "light rain and snow":
        return <WiSleet  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "rain and snow":
        return <WiSleet  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "light shower snow":
        return <WiSnow  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "shower snow":
        return <WiSnow  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "heavy shower snow":
        return <WiSnow  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      
      case "fog":
        return <WiFog  style={{ fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "mist":
        return <WiFog  style={{fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "haze":
        return <WiFog  style={{fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "smoke":
        return <WiFog  style={{fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "sand/dust whirls":
        return <WiFog  style={{fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "sand":
        return <WiFog  style={{fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "dust":
        return <WiFog  style={{fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "volcanic ash":
        return <WiFog  style={{fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "squalls":
        return <WiFog  style={{fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      case "tornado":
        return <WiFog  style={{fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
      default:
        return <WiRainMix  style={{fontSize: window.innerWidth < 520 ? "65px" : "70px",  color: "white" }} />;
    }
  };

  const getBackgroundColor = (weatherCode, weatherDescription, currentTime, sunrise, sunset, timezoneOffset) => {
    const localTime = new Date((currentTime * 1000) + (timezoneOffset * 1000));
    const sunriseDate = new Date((sunrise + timezoneOffset) * 1000);
    const sunsetDate = new Date((sunset + timezoneOffset) * 1000);
    const isNightTime = localTime < sunriseDate || localTime >= sunsetDate;

    switch (weatherCode) {
      case "Clear":
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--clear))";
        } else {
          return "linear-gradient(to bottom, var(--clear), var(--tertiary))";
        }
      case "Haze":
        if (isNightTime) {
            return "linear-gradient(to bottom, var(--night1), var(--clear))";
        } else {
            return "linear-gradient(to bottom, var(--clouds), var(--rain))";
        }  
      case "Clouds":
        if (isNightTime) {
        return "linear-gradient(to bottom, var(--night1), var(--clouds))";
        } else {
          if (weatherDescription === 'few clouds'){
            return "linear-gradient(to bottom, var(--clear), var(--clouds))";
          }
          return "linear-gradient(to bottom, var(--clouds), var(--clouds))";
        }
      case "Rain":
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--rain))";
          } else {
            return "linear-gradient(to bottom, var(--rain), var(--rain))";
        }
      case "Drizzle":
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--rain))";
        } else {
          return "linear-gradient(to bottom, var(--rain), var(--rain))";
        }     
      case "Snow":
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--snow))";
          } else {
            return "linear-gradient(to bottom, var(--snow), var(--snow))";
          }
      default:
        if(loading) {
          return "linear-gradient(to bottom, var(--text), var(--text))";
        }
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--clouds))";
          } else {
          return "linear-gradient(to bottom, var(--clouds), var(--clouds))";
        }
    }
  };

  const getWindDirection = (degrees) => {
    if (degrees >= 0 && degrees <= 22.5) return "N↓";
    if (degrees > 22.5 && degrees <= 67.5) return "NE↙";
    if (degrees > 67.5 && degrees <= 112.5) return "E←";
    if (degrees > 112.5 && degrees <= 157.5) return "SE↖";
    if (degrees > 157.5 && degrees <= 202.5) return "S↑";
    if (degrees > 202.5 && degrees <= 247.5) return "SW↗";
    if (degrees > 247.5 && degrees <= 292.5) return "W→";
    if (degrees > 292.5 && degrees <= 337.5) return "NW↘";
    return "N"; // Default to North for degrees >= 337.5 and for any unexpected values
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 520);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 520);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    
      <Paper
        elevation={3}
        style={{
          display: "flex", // Add flex display
          flexDirection: "column", // Align children in a row
          justifyContent: "space-between", // Distribute space between main axis items
          backgroundImage: getBackgroundColor(weatherData?.weather[0].main, weatherData?.weather[0].description, weatherData?.dt, weatherData?.sys?.sunrise, weatherData?.sys.sunset, weatherData?.timezone), 
          marginBottom: isMobile ? '8px' : '16px',
          borderRadius: "20px",
          boxShadow: "0px 4px 12px rgba(0,0,0,0.6)",
          padding: "20px",
          height : isMobile ? "163px" : "170px"
        }}
      >
        {weatherData && (
          <Suspense >
          <CurrentWeatherCardContents weatherData={weatherData} isMobile={isMobile} kelvinToUnit={kelvinToUnit} getWeatherIcon={getWeatherIcon} getWindDirection={getWindDirection} extraInfoLocationName={extraInfoLocationName}></CurrentWeatherCardContents>
        </Suspense>   
        )}
      </Paper>
  );
}

export default CurrentWeatherCard;
