import React, {Suspense, lazy, useState, useEffect } from "react";

import "../App.css";
import {
  IconButton,
  Paper,
  CardContent,
  Typography,
  Divider,
} from "@mui/material";
import AirIcon from "@mui/icons-material/Air";
import { useNavigate } from "react-router-dom";
import OpacityIcon from "@mui/icons-material/Opacity";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTemperatureUnit } from '../TemperatureUnitContext.js';
import { kelvinToUnit } from './customHooks.js';
const DayList = lazy(() => import('./dayListItem.js'));
const Day = lazy(() => import('./day.js'));

const WeatherCard = ({ weatherData, clickedRegion}) => {
  const [expanded, setExpanded] = useState(false);
  const { unit } = useTemperatureUnit();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 440);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 440);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getItemsToShow = () => {
    if (windowWidth >= 850) return 11;
    if (windowWidth >= 800) return 10;
    if (windowWidth >= 750) return 9;
    if (windowWidth >= 700) return 8;
    if (windowWidth >= 650) return 7;
    if (windowWidth >= 580) return 6;
    if (windowWidth >= 550) return 5;
    if (windowWidth >= 500) return 4;
    return 5; // mobile
  };

  const handleLocationClick = () => {
    const continent = clickedRegion.toLowerCase().replace(/ /g, '-');
    const location = weatherData.name.split(",");
    const formatLocation = location[0].toLowerCase().replace(/ /g, '-');
    navigate(`/sunradar/${continent}/${formatLocation}`);
  };

  return (
    <Paper
      className="weather-card"
      elevation={3}
      style={{
        backgroundColor: 'white',
        marginBottom: isMobile ? '4px' : '16px',
        
        borderRadius: "20px",
        boxShadow: "0px 4px 12px rgba(0,0,0,0.6)",
      }}
      onClick={handleToggleExpand}
    >
      <CardContent style={{ position: "relative" }}>
        <div
          style={{
            maxWidth: "100%", 
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Typography
              variant={isMobile ? "h7" : "h6"}
              component="div"
              style={{
                fontWeight: "bold",
                marginBottom: "8px",
                textAlign: "left",
                color: 'var(--secondary)',
                paddingRight: "10px",
                cursor: 'pointer'
              }}
              onClick={handleLocationClick}
              onMouseOver={(e) => e.currentTarget.style.textDecoration = 'underline'} // Underline on hover
              onMouseOut={(e) => e.currentTarget.style.textDecoration = 'none'}
            >
              {weatherData.name}{", "}
              {weatherData.country === 'United States' ? 'USA' : weatherData.country === 'United Arab Emirates' ? 'UAE' : weatherData.country}
              <img
                src={weatherData.flag}
                alt={`${weatherData.name} flag`}
                style={{
                  marginLeft: "8px", // Adjust the spacing between the city name and the flag
                  width: "22px",
                  height: "16px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)", // Add a shadow
                  borderRadius: "4px",
                }}
              />
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "-10px",
            }}
          >
            <Typography
              variant="body1"
              style={{
                fontSize: isMobile ? '14px' : '18px',
                fontWeight: "bold",
                color: "var(--secondary)",
                marginRight: "0px",
                display: isMobile ? "flex" : "flex",
              }}
            >
             Avg: {kelvinToUnit(unit, weatherData.avgTemp)}°
            </Typography>
            <IconButton
              onClick={handleToggleExpand}
              style={{
                transform: expanded ? "rotate(180deg)" : "", // Rotate the icon
                transition: "transform 0.3s",
                fontSize: isMobile ? "20px" : "24px",
                color: 'var(--secondary)'
              }}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
        </div>

        <div
          style={{
            display: !expanded ? "flex" : "none",
            justifyContent: "space-around",
            width: "100%",
            gap: "1px",
          }}
        >
          {weatherData.weatherInfo
            .slice(0, getItemsToShow())
            .map((day, index) => (
              <Suspense key={index} fallback={<div style ={{minHeight: '115px', // Set based on typical height of loaded components
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'}}></div>}> 
              <Day timezone={weatherData.timezone} day = {day} index = {index} isMobile = {isMobile} card ={true}></Day>
              </Suspense>
            ))}
        </div>

        <div
          className="weather-grid"
          style={{
            display: expanded ? "grid" : "none",
            gap: isMobile ? "4px" : "16px",
          }}
        >
          {weatherData.weatherInfo.map((day, index) => (
              <Suspense key={index} fallback={<div></div>}> 
              <DayList timezone={weatherData.timezone} day={day} index={index} isMobile={isMobile} />
           </Suspense>
          ))}
        </div>
      </CardContent>
    </Paper>
  );
};

export default WeatherCard;
