import React, { useMemo } from 'react'

import { WiDaySunny, WiCloud, WiCloudy, WiThunderstorm, WiRainMix, WiRain, WiSleet, WiSnow, WiFog } from 'react-icons/wi'

export const useWeatherIcon = (description, isMobile, card, pop) => {
  return useMemo(() => {
    switch (description) {
      case 'sky is clear':
        if (card) {
          return <WiDaySunny style={{ fontSize: isMobile ? '35px' : '45px', color: 'yellow' }} />
        } else {
          return <WiDaySunny style={{ fontSize: isMobile ? '35px' : '45px', color: 'hsl(48, 110%, 50%)' }} />; // hsl(39, 100%, 50%)
        }

      case 'few clouds':
        return <img src='/cloud_sun.ico' alt='Day Sunny Overcast' style={{ width: isMobile ? '35px' : '40px', height: isMobile ? '32px' : '38px', padding: isMobile ? '0px 0px 2px 0px' : '0px 0px 7px 5px' }} />
      case 'scattered clouds':
        return <WiCloud style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'broken clouds':
        return <WiCloudy style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'overcast clouds':
        return <WiCloudy style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />

      case 'thunderstorm with light rain':
        return <WiThunderstorm style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'thunderstorm with rain':
        return <WiThunderstorm style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'thunderstorm with heavy rain':
        return <WiThunderstorm style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'light thunderstorm':
        return <WiThunderstorm style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'thunderstorm':
        return <WiThunderstorm style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'heavy thunderstorm':
        return <WiThunderstorm style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'ragged thunderstorm':
        return <WiThunderstorm style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'thunderstorm with light drizzle':
        return <WiThunderstorm style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'thunderstorm with drizzle':
        return <WiThunderstorm style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'thunderstorm with heavy drizzle':
        return <WiThunderstorm style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />

      case 'light rain':
        if(pop < 0.5){
          return <WiCloudy style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
        } else {
        return <WiRainMix style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
        }
      case 'moderate rain':
        if(pop < 0.5){
          return <WiCloudy style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
        } else {
        return <WiRainMix style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
        }
      case 'heavy intensity rain':
        return <WiRain style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'very heavy rain':
        return <WiRain style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'extreme rain':
        return <WiRain style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'freezing rain':
        return <WiSleet style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'light intensity shower rain':
        return <WiRainMix style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'shower rain':
        return <WiRainMix style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'heavy intensity shower rain':
        return <WiRain style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'ragged shower rain':
        return <WiRain style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />

      case 'light snow':
        return <WiSnow style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'snow':
        return <WiSnow style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'heavy snow':
        return <WiSnow style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'sleet':
        return <WiSleet style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'light shower sleet':
        return <WiSleet style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'shower sleet':
        return <WiSleet style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'light rain and snow':
        return <WiSleet style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'rain and snow':
        return <WiSleet style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'light shower snow':
        return <WiSnow style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'shower snow':
        return <WiSnow style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'heavy shower snow':
        return <WiSnow style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />

      case 'fog':
        return <WiFog style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'mist':
        return <WiFog style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      case 'haze':
        return <WiFog style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
      default:
        return <WiRainMix style={{ fontSize: isMobile ? '35px' : '45px', color: '#555555' }} />
    }
  }, [description, isMobile, card])
}


export const useBackgroundColor = (main, description, pop) => {
  return useMemo(() => {
    switch (main) {
      case 'Clear':
        return 'linear-gradient(to bottom, var(--clear), var(--clear))'
      case 'Clouds':
        if (description === 'few clouds') {
          return 'linear-gradient(to bottom, var(--clear), var(--clouds))'
        }
        return 'linear-gradient(to bottom, var(--clouds), var(--clouds))'
      case 'Rain':
        if(pop < 0.5){
          return 'linear-gradient(to bottom, var(--clouds), var(--rain))'
        }else{
          return 'linear-gradient(to bottom, var(--rain), var(--rain))'
        }
      case 'Snow':
        return 'linear-gradient(to bottom, var(--snow), var(--snow))'
      default:
        return 'linear-gradient(to bottom, var(--clear), var(--clear))'
    }
  }, [main, description])
}

export const useWindDirection = (degrees) => {
  return useMemo(() => {
    if (degrees >= 0 && degrees <= 22.5) return 'N↓'
    if (degrees > 22.5 && degrees <= 67.5) return 'NE↙'
    if (degrees > 67.5 && degrees <= 112.5) return 'E←'
    if (degrees > 112.5 && degrees <= 157.5) return 'SE↖'
    if (degrees > 157.5 && degrees <= 202.5) return 'S↑'
    if (degrees > 202.5 && degrees <= 247.5) return 'SW↗'
    if (degrees > 247.5 && degrees <= 292.5) return 'W→'
    if (degrees > 292.5 && degrees <= 337.5) return 'NW↘'
    return 'N'
  }, [degrees])
}

// not a hook but belongs in this file
export const kelvinToUnit = (unit,tempDay) => {
  if (unit === 'degC') {
    return Math.round((tempDay - 273.15))
  }
  return Math.round(((tempDay - 273.15) * 9 / 5 + 32))
}

export const useDayAndDate = (index, timezone) => {
  return useMemo(() => {
    if (index === 0) {
      return "Today";
    }
  
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const today = new Date();
    
    // Adjust for timezone offset
    const targetDate = new Date(
      today.getTime() + timezone * 1000 + index * 24 * 60 * 60 * 1000
    );
  
    const dayName = daysOfWeek[targetDate.getDay()];
    const dateStr = targetDate.toLocaleDateString("en-GB", { day: "numeric" });
  
    return `${dayName} ${dateStr}`;
  }, [index, timezone])
}
