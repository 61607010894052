import React, { useState, useEffect, lazy, Suspense } from "react";

import "../../App.css";
import {
  Paper,
  CardContent,
  Typography,
  Divider,
} from "@mui/material";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import {
  WiRaindrops,
  WiHumidity,
  WiThermometer,
  WiStrongWind,
  WiSunrise,
  WiSunset,
} from "weather-icons-react";
import { useTemperatureUnit } from '../../TemperatureUnitContext';

const CurrentWeatherStatsCardContents = lazy(() => import('./contents/currentWeatherStatsCardContents.js'));

function CurrentWeatherStatsCard({ weatherData, timezoneInfo, loading }) {
  
  const { unit } = useTemperatureUnit();
  
  const kelvinToUnit = (kelvin) => {
    if(unit === 'degC') {
      return Math.round((kelvin - 273.15).toFixed(2));
    } 
    return Math.round(((kelvin - 273.15) * 9/5 + 32).toFixed(2)); 
  };

    const getWindDirection = (degrees) => {
        if (degrees >= 0 && degrees <= 22.5) return "N↓";
        if (degrees > 22.5 && degrees <= 67.5) return "NE↙";
        if (degrees > 67.5 && degrees <= 112.5) return "E←";
        if (degrees > 112.5 && degrees <= 157.5) return "SE↖";
        if (degrees > 157.5 && degrees <= 202.5) return "S↑";
        if (degrees > 202.5 && degrees <= 247.5) return "SW↗";
        if (degrees > 247.5 && degrees <= 292.5) return "W→";
        if (degrees > 292.5 && degrees <= 337.5) return "NW↘";
        return "N"; // Default to North for degrees >= 337.5 and for any unexpected values
    };

    function convertTime(unixTimestamp, timezoneOffsetInSeconds) {
  
      const adjustedTimestamp = unixTimestamp * 1000 + timezoneOffsetInSeconds * 1000;
      const date = new Date(adjustedTimestamp);
    
      let hours = date.getUTCHours(); // Use getUTCHours to extract the hour after adjusting the timestamp
      const minutes = "0" + date.getUTCMinutes(); // Use getUTCMinutes to ensure consistency
    
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
    
      const formattedTime = `${hours}:${minutes.substr(-2)} ${ampm}`;
      return formattedTime;
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 440);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 440);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Paper
          className="weather-card"
          elevation={3}
          style={{
            // backgroundImage: getBackgroundColor(firstDayForecast.main),
            marginBottom: isMobile ? '8px' : '16px',
            borderRadius: "20px",
            boxShadow: "0px 4px 12px rgba(0,0,0,0.6)",
            height : isMobile ? "238px" : "287px"
          }}
        >
          
          {weatherData && (
            <Suspense >
              <CurrentWeatherStatsCardContents firstDayForecast={weatherData[0]} timezoneInfo={timezoneInfo} isMobile={isMobile} kelvinToUnit={kelvinToUnit} convertTime={convertTime} getWindDirection={getWindDirection}></CurrentWeatherStatsCardContents>
            </Suspense>   
          )}
        </Paper>
)}

export default CurrentWeatherStatsCard;