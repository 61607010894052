import React, {lazy, useState, useEffect, Suspense } from "react";

import "../../App.css";
import {
  Paper,
  
} from "@mui/material";

import {
  WiDaySunny,
  WiCloud,
  WiDayCloudy,
  WiCloudy,
  WiRainMix,
  WiSnow,
  WiRain,
  WiThunderstorm,
  WiFog,
  WiSleet,
  WiSnowflakeCold,
  WiNightAltPartlyCloudy,
  WiNightClear,
  
} from "weather-icons-react";
import { useTemperatureUnit } from "../../TemperatureUnitContext";

const ThreeHourPeriodCardContents = lazy(() => import('./contents/threeHourPeriodCardContents.js'));


function ThreeHourPeriodCard({ weatherData, city, currentWeather }) {
  const [expanded, setExpanded] = useState(false);

  const { unit } = useTemperatureUnit();

  const kelvinToUnit = (kelvin) => {
    if (unit === "degC") {
      return Math.round((kelvin - 273.15).toFixed(2));
    }
    return Math.round((((kelvin - 273.15) * 9) / 5 + 32).toFixed(2));
  };

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const formatDay = (timestampStr, timezoneOffsetInSeconds) => {
    const dateObject = new Date(timestampStr);

    dateObject.setSeconds(dateObject.getSeconds() + timezoneOffsetInSeconds);

    const options = { weekday: "long", day: "numeric", month: "long" };
    return dateObject.toLocaleDateString("en-US", options);
  };


  const formatDate = (timestampStr, timezoneOffsetInSeconds) => {
    // Create a Date object from the timestamp string
    const utcTimestamp = new Date(timestampStr + "Z"); // Append 'Z' to indicate UTC time

    // Apply the timezone offset from the API to get the local time of the weather location
    const localTimestamp = new Date(
      utcTimestamp.getTime() + timezoneOffsetInSeconds * 1000
    );

    // Use getUTC* methods to get date components for comparison in a standardized way
    const year = localTimestamp.getUTCFullYear();
    const month = (localTimestamp.getUTCMonth() + 1)
      .toString()
      .padStart(2, "0");
    const day = localTimestamp.getUTCDate().toString().padStart(2, "0");

    // Return the formatted date string
    return `${year}-${month}-${day}`;
  };

  const formatTimestamp = (timestampStr, timezoneOffsetInSeconds) => {
    // Create a Date object from the timestamp string
    const utcTimestamp = new Date(timestampStr + "Z"); // Append 'Z' to indicate UTC time

    // Apply the timezone offset from the API to get the local time of the weather location
    const localTimestamp = new Date(
      utcTimestamp.getTime() + timezoneOffsetInSeconds * 1000
    );

    // Use getUTC* methods to get time components for display
    let hours = localTimestamp.getUTCHours().toString().padStart(2, "0");
    const minutes = localTimestamp.getUTCMinutes().toString().padStart(2, "0");

    // Return the formatted time string
    return `${hours}:${minutes}`;
  };

  const getWeatherIcon = (
    card,
    weatherCode,
    timestampStr,
    sunrise,
    sunset,
    timezoneOffset
  ) => {
    // Convert the sunrise and sunset times to Date objects, adjusted by the timezone offset
    const sunriseDate = new Date((sunrise + timezoneOffset) * 1000);
    const sunsetDate = new Date((sunset + timezoneOffset) * 1000);

    // Extract the hours for sunrise and sunset, which will be used for comparison
    const sunriseHour = sunriseDate.getUTCHours();
    const sunsetHour = sunsetDate.getUTCHours();

    // Convert the current timestamp string to a Date object, adjusted for timezone
    const currentDate = new Date(
      new Date(timestampStr.replace(/-/g, "/")).getTime() +
        timezoneOffset * 1000
    );
    const currentHour = currentDate.getHours();

    // Determine if the current time is between sunrise and sunset hours
    const isDayTime = currentHour >= sunriseHour && currentHour < sunsetHour;

    switch (weatherCode) {
      case "clear sky":
        if (isDayTime) {
          // During the day
          if (card) {
            // If card is true, set color to yellow
            return <WiDaySunny alt="sunny" style={{ fontSize: "45px", color: "yellow" }} />;
          } else {
            // If card is not true, set color to orange (adjusted HSL value from your example)
            return (
              <WiDaySunny alt="sunny" 
                style={{ fontSize: "45px", color: "hsl(48, 100%, 50%)" }}
              />
            );
          }
        } else {
          // During the night
          return (
            <WiNightClear
              style={{ fontSize: "45px", color: "var(--altmoon)" }}
            />
          );
        }

      case "few clouds":
        return isDayTime ? (
          <img
            src="cloud_sun.ico"
            alt="Day Sunny Overcast"
            style={{
              width: "40px",
              height: "38px",
              padding: "3px 0px 1px 4px",
            }}
          />
        ) : (
          // ? <WiDaySunnyOvercast style={{ fontSize: "40px", color: "var(--altcloud)" }} />
          <WiNightAltPartlyCloudy alt="cloudy"
            style={{ fontSize: "45px", color: "var(--altcloud)" }}
          />
        );
      case "scattered clouds":
        return (
          <WiCloud alt="cloudy" style={{ fontSize: "45px", color: "var(--altcloud)" }} />
        );
      case "broken clouds":
        return (
          <WiCloudy alt="cloudy" style={{ fontSize: "45px", color: "var(--altcloud)" }} />
        );
      case "overcast clouds":
        return (
          <WiCloudy alt="cloudy" style={{ fontSize: "45px", color: "var(--altcloud)" }} />
        );
      case "thunderstorm with light rain":
        return <WiThunderstorm alt="Thunderstorm" style={{ fontSize: "45px", color: "white" }} />;
      case "thunderstorm with rain":
        return <WiThunderstorm alt="Thunderstorm" style={{ fontSize: "45px", color: "white" }} />;
      case "thunderstorm with heavy rain":
        return <WiThunderstorm alt="Thunderstorm" style={{ fontSize: "45px", color: "white" }} />;
      case "light thunderstorm":
        return <WiThunderstorm alt="Thunderstorm" style={{ fontSize: "45px", color: "white" }} />;
      case "thunderstorm":
        return <WiThunderstorm alt="Thunderstorm" style={{ fontSize: "45px", color: "white" }} />;
      case "heavy thunderstorm":
        return <WiThunderstorm alt="Thunderstorm" style={{ fontSize: "45px", color: "white" }} />;
      case "ragged thunderstorm":
        return <WiThunderstorm alt="Thunderstorm" style={{ fontSize: "45px", color: "white" }} />;
      case "thunderstorm with light drizzle":
        return <WiThunderstorm alt="Thunderstorm" style={{ fontSize: "45px", color: "white" }} />;
      case "thunderstorm with drizzle":
        return <WiThunderstorm alt="Thunderstorm" style={{ fontSize: "45px", color: "white" }} />;
      case "thunderstorm with heavy drizzle":
        return <WiThunderstorm alt="Thunderstorm" style={{ fontSize: "45px", color: "white" }} />;

      case "light rain":
        return <WiRainMix alt="Rain" style={{ fontSize: "45px", color: "#555555" }} />;
      case "moderate rain":
        return <WiRainMix alt="Rain" style={{ fontSize: "45px", color: "#555555" }} />;
      case "heavy intensity rain":
        return <WiRain alt="Rain" style={{ fontSize: "45px", color: "#555555" }} />;
      case "very heavy rain":
        return <WiRain alt="Rain" style={{ fontSize: "45px", color: "#555555" }} />;
      case "extreme rain":
        return <WiRain alt="Rain" style={{ fontSize: "45px", color: "#555555" }} />;
      case "freezing rain":
        return <WiSleet alt="Rain" style={{ fontSize: "45px", color: "#555555" }} />;
      case "light intensity shower rain":
        return <WiRainMix alt="Rain" style={{ fontSize: "45px", color: "#555555" }} />;
      case "shower rain":
        return <WiRainMix alt="Rain" style={{ fontSize: "45px", color: "#555555" }} />;
      case "heavy intensity shower rain":
        return <WiRain alt="Rain" style={{ fontSize: "45px", color: "#555555" }} />;
      case "ragged shower rain":
        return <WiRain alt="Rain" style={{ fontSize: "45px", color: "#555555" }} />;

      case "light snow":
        return (
          <WiSnowflakeCold alt="Snow" style={{ fontSize: "45px", color: "var(--snow)" }} />
        );
      case "snow":
        return (
          <WiSnowflakeCold alt="Snow" style={{ fontSize: "45px", color: "var(--snow)" }} />
        );
      case "heavy snow":
        return (
          <WiSnowflakeCold alt="Snow" style={{ fontSize: "45px", color: "var(--snow)" }} />
        );
      case "sleet":
        return (
          <WiSnowflakeCold alt="Snow" style={{ fontSize: "45px", color: "var(--snow)" }} />
        );
      case "light shower sleet":
        return <WiSleet alt="Snow" style={{ fontSize: "45px", color: "var(--snow)" }} />;
      case "shower sleet":
        return <WiSleet alt="Snow" style={{ fontSize: "45px", color: "var(--snow)" }} />;
      case "light rain and snow":
        return <WiSleet alt="Snow" style={{ fontSize: "45px", color: "var(--snow)" }} />;
      case "rain and snow":
        return <WiSleet alt="Snow" style={{ fontSize: "45px", color: "var(--snow)" }} />;
      case "light shower snow":
        return <WiSnow alt="Snow" style={{ fontSize: "45px", color: "var(--snow)" }} />;
      case "shower snow":
        return <WiSnow alt="Snow" style={{ fontSize: "45px", color: "var(--snow)" }} />;
      case "heavy shower snow":
        return <WiSnow alt="Snow" style={{ fontSize: "45px", color: "var(--snow)" }} />;

      case "fog":
        return <WiFog alt="Fog" style={{ fontSize: "45px", color: "#555555" }} />;
      case "mist":
        return <WiFog alt="Fog" style={{ fontSize: "45px", color: "#555555" }} />;
      case "haze":
        return <WiFog alt="Fog" style={{ fontSize: "45px", color: "#555555" }} />;
      case "smoke":
        return <WiFog alt="Fog" style={{ fontSize: "45px", color: "#555555" }} />;
      case "sand/dust whirls":
        return <WiFog alt="Fog" style={{ fontSize: "45px", color: "#555555" }} />;
      case "sand":
        return <WiFog alt="Fog" style={{ fontSize: "45px", color: "#555555" }} />;
      case "dust":
        return <WiFog alt="Fog" style={{ fontSize: "45px", color: "#555555" }} />;
      case "volcanic ash":
        return <WiFog alt="Fog" style={{ fontSize: "45px", color: "#555555" }} />;
      case "squalls":
        return <WiFog alt="Fog" style={{ fontSize: "45px", color: "#555555" }} />;
      case "tornado":
        return <WiFog alt="Fog" style={{ fontSize: "45px", color: "#555555" }} />;
      default:
        return <WiRainMix alt="Fog" style={{ fontSize: "45px", color: "#555555" }} />;
    }
  };

  const getBackgroundColorForInnerCard = (
    weatherCode,
    timestampStr,
    sunrise,
    sunset,
    timezoneOffset,
    weatherDescription
  ) => {
    const sunriseDate = new Date((sunrise + timezoneOffset) * 1000);
    const sunsetDate = new Date((sunset + timezoneOffset) * 1000);
    const sunriseHour = sunriseDate.getUTCHours();
    const sunsetHour = sunsetDate.getUTCHours();
    const currentDate = new Date(new Date(timestampStr.replace(/-/g, "/")).getTime() +timezoneOffset * 1000);
    const currentHour = currentDate.getHours();
    const isDayTime = currentHour >= sunriseHour && currentHour < sunsetHour;

    switch (weatherCode) {
      case "Clear":
        if (isDayTime) {
          return "linear-gradient(to bottom, var(--clear), var(--clear))";
        } else {
          return "linear-gradient(to bottom, var(--night1), var(--tertiary))";
        }
      case "Clouds":
        if (isDayTime) {
          if (weatherDescription == "few clouds") {
            return "linear-gradient(to bottom, var(--clear), var(--clouds))";
          }
          return "linear-gradient(to bottom, var(--clouds), var(--clouds))";
        } else {
          return "linear-gradient(to bottom, var(--night1), var(--clouds))";
        }
      case "Rain":
        if (isDayTime) {
          return "linear-gradient(to bottom, var(--rain), var(--rain))";
        } else {
          return "linear-gradient(to bottom, var(--night1), var(--rain))";
        }
      case "Drizzle":
        if (isDayTime) {
          return "linear-gradient(to bottom, var(--rain), var(--rain))";
        } else {
          return "linear-gradient(to bottom, var(--night1), var(--rain))";
        }
      case "Snow":
        if (isDayTime) {
          return "linear-gradient(to bottom, var(--snow), var(--snow))";
        } else {
          return "linear-gradient(to bottom, var(--night1), var(--snow))";
        }
      default:
        return "linear-gradient(to bottom, var(--clear), var(--clear))";
    }
  };

  const getBackgroundColor = (weatherCode) => {
    switch (weatherCode) {
      case "Clear":
        return "linear-gradient(to bottom, var(--secondary), var(--tertiary))";
      case "Clouds":
        return "linear-gradient(to bottom, var(--2), var(--3))";
      case "Rain":
        return "linear-gradient(to bottom, var(--1), var(--2))";
      case "Snow":
        return "linear-gradient(to bottom, var(--1), var(--2))";
      default:
        return "linear-gradient(to bottom, var(--clouds), var(--clouds))";
    }
  };

  const getWindDirection = (degrees) => {
    if (degrees >= 0 && degrees <= 22.5) return "N↓";
    if (degrees > 22.5 && degrees <= 67.5) return "NE↙";
    if (degrees > 67.5 && degrees <= 112.5) return "E←";
    if (degrees > 112.5 && degrees <= 157.5) return "SE↖";
    if (degrees > 157.5 && degrees <= 202.5) return "S↑";
    if (degrees > 202.5 && degrees <= 247.5) return "SW↗";
    if (degrees > 247.5 && degrees <= 292.5) return "W→";
    if (degrees > 292.5 && degrees <= 337.5) return "NW↘";
    return "N"; // Default to North for degrees >= 337.5 and for any unexpected values
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getItemsToShow = () => {
    if (windowWidth >= 700) return 7;
    if (windowWidth >= 650) return 7;
    if (windowWidth >= 580) return 6;
    if (windowWidth >= 550) return 5;
    if (windowWidth >= 500) return 4;
    return 4; // mobile
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 440);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 440);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Paper
      className="weather-card"
      elevation={3}
      style={{
        // backgroundImage: getBackgroundColor(weatherData.main),
        marginBottom: isMobile ? "8px" : "16px",
        borderRadius: "20px",
        boxShadow: "0px 4px 12px rgba(0,0,0,0.6)",
        width: "100%",
        height: !expanded ? (isMobile ? '193px' : '228px') : 'auto'
      }}
      onClick={handleToggleExpand}
    >
      {(currentWeather && weatherData) && (
        <Suspense>
          <ThreeHourPeriodCardContents currentWeather={currentWeather} weatherData={weatherData} isMobile={isMobile} handleToggleExpand={handleToggleExpand} expanded={expanded}
            getBackgroundColor={getBackgroundColor} expanded={expanded} getBackgroundColorForInnerCard={getBackgroundColorForInnerCard} formatTimestamp={formatTimestamp}
            kelvinToUnit={kelvinToUnit} formatDay={formatDay} getWeatherIcon={getWeatherIcon} getWindDirection={getWindDirection} getItemsToShow={getItemsToShow} formatDate={formatDate}>
          </ThreeHourPeriodCardContents>
      </Suspense>
      )}

    </Paper>
  );
}

export default ThreeHourPeriodCard;
