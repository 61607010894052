import "./App.css";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
  
} from "react-router-dom";
import Footer from './Footer'; // Adjust the path as necessary
import Sunradar from "./sunradar/sunradarPage.js";
import WeatherForecast from "./weatherForecast/weatherForecastPage.js";
import LocationPage from "./location/locationPage.js";
import Blog from "./blogs/blog.js"
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { TemperatureUnitProvider } from './TemperatureUnitContext';


const Content = ({ appBarBackground, onWeatherUpdate }) => {
  const location = useLocation(); // This hook provides access to the current path

  useEffect(() => {
    function trackPageView(url) {
      window.gtag('config', 'G-CR0QLN5GNY', {
        page_path: url,
      });
    }
    trackPageView(location.pathname + location.search);
  }, [location]);
    

  const appBarColor = "transparent";

  const mainDivBackground =
    location.pathname === "/sunradar" || location.pathname === "/sunradar/"
      ? { backgroundImage: "linear-gradient(to bottom, var(--clear), var(--clear-light))" }
      : { backgroundImage: appBarBackground }; // The background for the '/' route is based on the handleWeatherUpdate function

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 440);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 520);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={mainDivBackground}>
      <AppBar
        position="static"
        color="default"
        elevation={3}
        style={{ backgroundColor: appBarColor, }}
      >
        <Toolbar sx={{ flexWrap: "wrap", height: { xs: '30px', sm: '80px' }, alignItems: 'flex-end', marginBottom: { xs: '6px', sm: '30px' } }}>
          <Typography variant="h4" noWrap sx={{ flexGrow: 1 }}>
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            <img src="/weatherwiki_logo.webp" alt="WeatherWiki Logo" style={{ height: "1.2em", marginLeft: '-2px', marginRight: 4, marginBottom: '-10px' }} />
            {!isMobile && 'WeatherWiki'}
            </Link>
          </Typography>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              color: "white",
              marginRight: "20px",
              display: isMobile ? "none" : "flex",
              alignItems: "center", 
              justifyContent: "center", 
              backgroundColor: "transparent", 
              padding: "0px 0px", 
              borderRadius: "30px", 
              transition: "background-color 0.3s",
              textDecoration: isMobile ? 'underline' : 'none'
            }}
            onMouseOver={(e) => e.currentTarget.style.textDecoration = 'underline'} 
            onMouseOut={(e) => e.currentTarget.style.textDecoration = 'none'}
          >
            <Typography variant="h6" noWrap>
              Forecast
            </Typography>
          </Link>

          <Link
            to="/sunradar"
            style={{
                textDecoration: "none",
                color: "white",
                marginRight: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center", 
                backgroundColor: "transparent", 
                padding: "0px 8px", 
                borderRadius: "30px", 
                transition: "background-color 0.3s",
                textDecoration: isMobile ? 'underline' : 'none'
            }}
            onMouseOver={(e) => e.currentTarget.style.textDecoration = 'underline'} 
            onMouseOut={(e) => e.currentTarget.style.textDecoration = 'none'}
          >
            <Typography variant="h6" noWrap>
              Sunradar
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
      <Routes>
        <Route path="/" element={<WeatherForecast onWeatherUpdate={onWeatherUpdate} />} />
        <Route path="/sunradar" element={<Sunradar />} />
        {/* <Route path="/weather-news" element={<Blog />} /> */}
        <Route path="/sunradar/:continent/:location" element={<LocationPage onWeatherUpdate={onWeatherUpdate} />} />
      </Routes>
    </div>
  );
};

const App = () => {
  const [appBarBackground, setAppBarBackground] = useState(""); 
  const handleWeatherUpdate = (backgroundStyle) => {
    setAppBarBackground(backgroundStyle); 
  };

  return (
    <TemperatureUnitProvider>
    <Router>
      <Content
        appBarBackground={appBarBackground}
        onWeatherUpdate={handleWeatherUpdate}
      />
      <Footer/>
    </Router>
    </TemperatureUnitProvider>
  );
};

export default App;
