import React, { useRef, useState, useEffect, useCallback } from "react";
import "../App.css";
import { Container, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CurrentWeatherCard from "./cards/currentWeatherCard.js";
import CurrentWeatherStatsCard from "./cards/currentWeatherStatsCard.js";
import TwoWeekForecastCard from "./cards/twoWeekForecastCard.js";
import ThreeHourPeriodCard from "./cards/threeHourPeriodCard.js";
import TodayPeriodWeatherCard from "./cards/todayPeriodsCard.js";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import { ApiClient } from "../api/axiosClient.js";
import { useTemperatureUnit } from '../TemperatureUnitContext';
import { WiMoonThirdQuarter } from "weather-icons-react";
import {Helmet} from 'react-helmet-async'
import BlogCard from "../blogs/blogCard.js";
import { useSearchParams } from "react-router-dom";



function WeatherForecast({ onWeatherUpdate }) {
  const [currentWeatherData, setCurrentWeatherData] = useState(null);
  const [city, setCity] = useState("");
  const [twoWeekForecast, setTwoWeekForecast] = useState(null);
  const [threeHourPeriodsFiveDays, setThreeHourPeriodsFiveDays] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [coordinates, setCoordinates] = useState({ lat: null, lon: null });
  const [selectedLocation, setSelectedLocation] = useState("");
  const { toggleUnit } = useTemperatureUnit();
  const [isSearchFocused, setIsSearchFocused] = useState(false);


  const debounceTimer = useRef(null);

  useEffect(() => {

    const savedLocation = JSON.parse(sessionStorage.getItem("selectedLocation"));

    if (savedLocation) {
      const { locationName, lat, lon } = savedLocation;
      setSelectedLocation(locationName);
      setCoordinates({ lat: lat, lon: lon });
    } else {
      const fetchLocation = async () => {
        try {
          const instance = ApiClient.getInstance();
          const response = await instance.client.get("getlocation");
          const { lat, lon, city, regionName, country } = response.data;

          if (country === 'US') {
            toggleUnit('degF')
          }
          const locationName = city || regionName;

          setCoordinates({ lat: lon, lon: lat });
          setSelectedLocation(locationName);

          sessionStorage.setItem("selectedIp", JSON.stringify({ lat: lat, lon: lon }));
          sessionStorage.setItem("selectedLocation", JSON.stringify({ locationName, lat: lon, lon: lat }));
        } catch (error) {
          console.error("Failed to fetch location", error);
        }
      };

      fetchLocation();
    }

    const previouslySearched = JSON.parse(localStorage.getItem("searchHistory")) || [];
    setSuggestions(previouslySearched);
  }, [toggleUnit]);


  const onSuggestionClick = (suggestion) => {
    setCity("");
    setSelectedLocation(suggestion.label);
    const { coordinates } = suggestion;
    const [lat, lon] = coordinates;
    setCoordinates({ lat, lon });
    setSuggestions([]);

    const newLocation = { label: suggestion.label, coordinates };
    const history = JSON.parse(localStorage.getItem("searchHistory")) || [];
    const updatedHistory = [newLocation, ...history.filter(item => item.label !== newLocation.label)].slice(0, 5);
    localStorage.setItem("searchHistory", JSON.stringify(updatedHistory));

    sessionStorage.setItem('selectedLocation', JSON.stringify({
      locationName: suggestion.label,
      lat: lat,
      lon: lon
    }));


  };


  const fetchForecastData = useCallback(async () => {
    setLoading(true);
    try {
      const instance = ApiClient.getInstance();
      const currentWeatherUrl = `getcurrentWeatherForecast?lat=${coordinates.lat}&lon=${coordinates.lon}`;
      const twoWeekForecastUrl = `gettwoweekforecast?lat=${coordinates.lat}&lon=${coordinates.lon}`;
      const fiveDayForecastUrl = `getFiveDayForecast?lat=${coordinates.lat}&lon=${coordinates.lon}`;

      const [
        currentWeatherResponse,
        twoWeekForecastResponse,
        fiveDayForecastResponse,
      ] = await Promise.all([
        instance.client.get(currentWeatherUrl),
        instance.client.get(twoWeekForecastUrl),
        instance.client.get(fiveDayForecastUrl),
      ]);

      setCurrentWeatherData(currentWeatherResponse.data);
      setTwoWeekForecast(twoWeekForecastResponse.data);
      setThreeHourPeriodsFiveDays(fiveDayForecastResponse.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [coordinates.lat, coordinates.lon]);

  useEffect(() => {
    if (coordinates.lat !== null && coordinates.lon !== null) {
      fetchForecastData();
    }
  }, [coordinates, fetchForecastData]); 

  useEffect(() => {
    if (currentWeatherData) {
      const backgroundStyle = getBackgroundColor(
        currentWeatherData.weather[0].main,
        currentWeatherData.weather[0].description,
        new Date().getTime() / 1000,
        currentWeatherData.sys.sunrise,
        currentWeatherData.sys.sunset,
        currentWeatherData.timezone
      );
      onWeatherUpdate(backgroundStyle);
    }
  }, [currentWeatherData, onWeatherUpdate]);

  const getBackgroundColor = (
    weatherCode,
    weatherDescription,
    currentTime,
    sunrise,
    sunset,
    timezoneOffset
  ) => {
    const localTime = new Date(currentTime * 1000 + timezoneOffset * 1000);
    const sunriseDate = new Date((sunrise + timezoneOffset) * 1000);
    const sunsetDate = new Date((sunset + timezoneOffset) * 1000);
    const isNightTime = localTime < sunriseDate || localTime >= sunsetDate;

    switch (weatherCode) {
      case "Clear":
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--clear))";
        } else {
          return "linear-gradient(to bottom, var(--clear), var(--text))";
        }
      case "Haze":
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--clear))";
        } else {
          return "linear-gradient(to bottom, var(--clouds), var(--rain))";
        }
      case "Clouds":
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--clouds))";
        } else {
          if (weatherDescription === 'few clouds') {
            return "linear-gradient(to bottom, var(--clear), var(--clouds))";
          }
          return "linear-gradient(to bottom, var(--clouds), var(--clouds))";
        }
      case "Rain":
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--rain))";
        } else {
          return "linear-gradient(to bottom, var(--rain), var(--text))";
        }
      case "Drizzle":
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--rain))";
        } else {
          return "linear-gradient(to bottom, var(--rain), var(--text))";
        }
      case "Snow":
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--snow))";
        } else {
          return "linear-gradient(to bottom, var(--snow), var(--text))";
        }
      default:
        if (isNightTime) {
          return "linear-gradient(to bottom, var(--night1), var(--clouds))";
        } else {
          return "linear-gradient(to bottom, var(--clouds), var(--clouds))";
        }
    }
  };

  const backgroundStyle = currentWeatherData
    ? getBackgroundColor(
      currentWeatherData.weather[0].main,
      currentWeatherData.weather[0].description,
      new Date().getTime() / 1000,
      currentWeatherData.sys.sunrise,
      currentWeatherData.sys.sunset,
      currentWeatherData.timezone
    )
    : "linear-gradient(to bottom, var(--clear), var(--text))";

    const getTodaysPeriods = () => {
      const currentTime = new Date();
  
      const getTimeOfDay = (timestamp, timezoneOffset, current) => {
        let dateObject = null
        let hour = null
        if(!current) {
          dateObject = new Date(new Date(timestamp.replace(/-/g, "/")).getTime() +timezoneOffset * 1000);
          hour = dateObject.getHours();
  
        } else {
          dateObject = new Date(timestamp.getTime() + timezoneOffset*1000)
          hour = dateObject.getUTCHours();
        }
      
        if (hour >= 6 && hour < 12) {
          return "Morning";
        } else if (hour >= 12 && hour < 18) {
          return "Afternoon";
        } else if (hour >= 18 && hour < 24) {
          return "Evening";
        } else {
          return "Overnight";
        }
      };
  
      const periodArray = ["Morning", "Afternoon", "Evening", "Overnight"];
  
    
      const currentPeriod = getTimeOfDay(
        currentTime,
        threeHourPeriodsFiveDays.city.timezone,
        true
      );
  
      let currentPeriodIndex = periodArray.indexOf(currentPeriod);
  
      const rotatedPeriodArray = periodArray
        .slice(currentPeriodIndex)
        .concat(periodArray.slice(0, currentPeriodIndex));
  
      const periods = rotatedPeriodArray.reduce((acc, period) => {
        acc[period] = {};
        return acc;
      }, {});
  
      const slicedWeatherInfo = threeHourPeriodsFiveDays.weatherInfo.slice(0, 8);
  
      rotatedPeriodArray.forEach((period) => {
        if (period === currentPeriod) {
          periods[period] = {
            temp: currentWeatherData.main.temp,
            main: currentWeatherData.weather[0].main,
            description: currentWeatherData.weather[0].description,
            timestamp: new Date()
              .toISOString()
              .replace("T", " ")
              .replace(/\.\d+Z$/, ""),
            sunrise: currentWeatherData.sys.sunrise,
            sunset: currentWeatherData.sys.sunset,
            timezone: currentWeatherData.timezone,
          };
        } else {
          const entry = slicedWeatherInfo.find(
            (entry) =>
              getTimeOfDay(
                entry.timestamp,
                threeHourPeriodsFiveDays.city.timezone, false
              ) === period
          );
  
          if (entry) {
            periods[period] = {
              main: entry.main,
              temp: entry.temp,
              description: entry.description,
              timestamp: entry.timestamp,
              sunrise: threeHourPeriodsFiveDays.city.sunrise,
              sunset: threeHourPeriodsFiveDays.city.sunset,
              timezone: threeHourPeriodsFiveDays.city.timezone,
            };
          }
        }
      });
      return periods;
    };


  const fetchSuggestions = useCallback((text, ipCoordinates) => {

    clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(async () => {
      if (text.length < 4) {
        const previouslySearched = JSON.parse(localStorage.getItem("searchHistory")) || [];
        setSuggestions(previouslySearched);
        return;
      }
      try {
        if (ipCoordinates) {
          const instance = ApiClient.getInstance();
          const response = await instance.client.get("getsuggestionswithfocus", {
            params: { text, lat: ipCoordinates.lat, lon: ipCoordinates.lon }
          });
          setSuggestions(response.data);
        } else {
          const instance = ApiClient.getInstance();
          const response = await instance.client.get("getsuggestions", {
            params: { text }
          });
          setSuggestions(response.data);
        }

      } catch (error) {
        console.error("Failed to fetch suggestions", error);
      }
    }, 500);
  }, []);

  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 440);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 440);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSearchBoxOnclick = () => {
    const previouslySearched = JSON.parse(localStorage.getItem("searchHistory")) || [];
    setSuggestions(previouslySearched);
    setIsSearchFocused(true)
  };

  const handleSuggestionBlur = (event) => {
    const newFocusTarget = event.relatedTarget;

    if (!newFocusTarget || !suggestionListRef.current.contains(newFocusTarget)) {
      setIsSearchFocused(false);
    }
  };

  const suggestionListRef = useRef(null);

  return (
    <>
      <Helmet>
        <title>Weather Forecast | Weather Wiki</title>
        <meta name="description" content="Highly accurate hourly, daily, and 2-week forecasts from around the globe."/>
        <link rel="canonical" href="https://www.weatherwiki.com"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <script type="application/ld+json">
        {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "Weather Wiki",
              "url": "https://www.weatherwiki.com",
              "logo": "https://www.weatherwiki.com/favicon.png"
            }
        `}
        </script>
      </Helmet>

    

    <div style={{ backgroundColor: "transparent" }}>
      <Container
        maxWidth="md"
        style={{
          margin: "10px auto",
          background: "transparent",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            fetchForecastData();
          }}
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            height: "48px",
            border: "none",
            borderRadius: "40px",
            marginBottom: isMobile ? "8px" : "16px",
            marginTop: isMobile ? "-12px" : "0px",
          }}
        >
          <input
            className={`search-input ${isMobile ? "search-input-mobile" : "search-input-desktop"
              }`}
            value={city}
            onClick={() => handleSearchBoxOnclick()}
            onBlur={(event) => handleSuggestionBlur(event)}

            onChange={(e) => {
              setCity(e.target.value);

              const ipCoordinates = JSON.parse(sessionStorage.getItem("selectedIp"))
              fetchSuggestions(e.target.value, ipCoordinates);
            }}


            placeholder="Search Location"
            type="text"
            style={{
              textTransform: "capitalize",
              flex: 1,
              borderColor: "transparent",
              padding: isMobile ? "10px 50px 5px 15px" : "15px 50px 10px 25px",
              borderRadius: "30px",
              outlineColor: "transparent",
            }}
          />
          <button
            type="submit"
            alt="search"
            style={{
              position: "absolute",
              right: "5px",
              top: "50%",
              transform: "translateY(-50%)",
              backgroundImage: backgroundStyle,
              border: "none",
              borderRadius: "50%",
              width: isMobile ? "30px" : "40px",
              height: isMobile ? "30px" : "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 0,
              cursor: "pointer",
              outline: "none",
            }}
          >
            <SearchIcon style={{ color: "var(--text)" }} />
          </button>
        </form>

        {isSearchFocused && suggestions?.length > 0 && (
          <Paper
            elevation={3}
            style={{
              padding: "10px",
              marginTop: "10px",
              borderRadius: "20px",
              marginBottom: "16px",
            }}
            ref={suggestionListRef}
            tabIndex={0}


          >
            {suggestions.slice(0, 5).map((suggestion, index) => (
              <div
                key={index}
                className="suggestion-item"
                onClick={() => onSuggestionClick(suggestion)}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  borderBottom: index !== suggestions.length - 1 ? "1px solid #ddd" : "none",
                  borderRadius: "10px",
                }}
              >
                {suggestion.label}
              </div>
            ))}
          </Paper>
        )
        }

        <CurrentWeatherCard
          weatherData={currentWeatherData}
          loading={loading}
        />

        <CurrentWeatherStatsCard
          weatherData={twoWeekForecast}
          timezoneInfo={currentWeatherData}
          loading={loading}
        />
        
        {!loading && threeHourPeriodsFiveDays && currentWeatherData && (
          <TodayPeriodWeatherCard periods={getTodaysPeriods()}/>
        )}

        <ThreeHourPeriodCard
          weatherData={threeHourPeriodsFiveDays}
          currentWeather={currentWeatherData}
        />

        <TwoWeekForecastCard
          forecastData={twoWeekForecast} 
          currentWeather={currentWeatherData}
        />
      </Container>
    </div>
  </>
  );
}

export default WeatherForecast;
