import React, {useState, useEffect} from "react";
import "../../App.css";
import { Typography, Paper, ListItemIcon, CardContent } from "@mui/material";
import {
  WiDaySunny,
  WiCloud,
  WiCloudy,
  WiRainMix,
  WiSnow,
  WiRain,
  WiThunderstorm,
  WiFog,
  WiSleet,
  WiNightAltPartlyCloudy,
  WiNightClear
} from 'weather-icons-react';
import { useTemperatureUnit } from '../../TemperatureUnitContext';


function TodayPeriodWeatherCard({ periods, loading }) {

  const { unit } = useTemperatureUnit();
  
  const kelvinToUnit = (kelvin) => {
    if(unit === 'degC') {
      return Math.round((kelvin - 273.15).toFixed(2));
    } 
    return Math.round(((kelvin - 273.15) * 9/5 + 32).toFixed(2)); 
  };

  const getWeatherIcon = (period, weatherCode, timestampStr, sunrise, sunset, timezoneOffset) => {

    const sunriseDate = new Date((sunrise + timezoneOffset) * 1000);
    const sunsetDate = new Date((sunset + timezoneOffset) * 1000);
    const currentDate = new Date(new Date(timestampStr.replace(/-/g, "/")).getTime() + timezoneOffset * 1000);

  
    let isDayTime;
    if (period === 'Morning' && sunriseDate.getUTCHours() >= 10) {
      isDayTime = false;
    }
    else if (period === 'Morning' && sunriseDate.getUTCHours() <=10) {
        isDayTime = true;
        
    } 
    else if (period === 'Evening' && sunsetDate.getUTCHours() >= 21 ) {
      isDayTime = true;
    }
    else if (period === 'Evening' && sunsetDate.getUTCHours() <= 21) {
      isDayTime = false;
    }
    else {
      const currentHour = currentDate.getHours();
      const sunriseHour = sunriseDate.getUTCHours();
      const sunsetHour = sunsetDate.getUTCHours();
      isDayTime = currentHour >= sunriseHour && currentHour < sunsetHour;
    }
  
    switch (weatherCode) {
      case "sky is clear":
        return isDayTime
          ? <WiDaySunny style={{ fontSize: "45px", color: "hsl(60, 90%, 50%)", }} />
          : <WiNightClear style={{ fontSize: "45px", color: "var(--altmoon)" }} />;
      case "clear sky":
        return isDayTime
          ? <WiDaySunny style={{ fontSize: "45px", color: "hsl(60, 90%, 50%)", }} />
          : <WiNightClear style={{ fontSize: "45px", color: "var(--altmoon)" }} />;
      case "few clouds":
        return isDayTime
          ? <img src="cloud_sun.ico" alt="Day Sunny Overcast" style={{ width: "40px", height: "38px", padding: '0px 0px 2px 0px' }} />
          : <WiNightAltPartlyCloudy style={{ fontSize: "45px", color: "var(--altcloud)" }} />;
      case "scattered clouds":
        return <WiCloud style={{ fontSize: "45px", color: "white" }} />;
      case "broken clouds":
        return <WiCloudy style={{ fontSize: "45px", color: "white" }} />;
      case "overcast clouds":
        return <WiCloudy style={{ fontSize: "45px", color: "white" }} />;

      case "thunderstorm with light rain":
        return <WiThunderstorm style={{ fontSize: "45px", color: "white" }} />;
      case "thunderstorm with rain":
        return <WiThunderstorm style={{ fontSize: "45px", color: "white" }} />;
      case "thunderstorm with heavy rain":
        return <WiThunderstorm style={{ fontSize: "45px", color: "white" }} />;
      case "light thunderstorm":
        return <WiThunderstorm style={{ fontSize: "45px", color: "white" }} />;
      case "thunderstorm":
        return <WiThunderstorm style={{ fontSize: "45px", color: "white" }} />;
      case "heavy thunderstorm":
        return <WiThunderstorm style={{ fontSize: "45px", color: "white" }} />;
      case "ragged thunderstorm":
        return <WiThunderstorm style={{ fontSize: "45px", color: "white" }} />;
      case "thunderstorm with light drizzle":
        return <WiThunderstorm style={{ fontSize: "45px", color: "white" }} />;
      case "thunderstorm with drizzle":
        return <WiThunderstorm style={{ fontSize: "45px", color: "white" }} />;
      case "thunderstorm with heavy drizzle":
        return <WiThunderstorm style={{ fontSize: "45px", color: "white" }} />;

      case "light rain":
        return <WiRainMix style={{ fontSize: "45px", color: "#555555" }} />;
      case "moderate rain":
        return <WiRainMix style={{ fontSize: "45px", color: "#555555" }} />;
      case "heavy intensity rain":
        return <WiRain style={{ fontSize: "45px", color: "#555555" }} />;
      case "very heavy rain":
        return <WiRain style={{ fontSize: "45px", color: "#555555" }} />;
      case "extreme rain":
        return <WiRain style={{ fontSize: "45px", color: "#555555" }} />;
      case "freezing rain":
        return <WiSleet style={{ fontSize: "45px", color: "#555555" }} />;
      case "light intensity shower rain":
        return <WiRainMix style={{ fontSize: "45px", color: "#555555" }} />;
      case "shower rain":
        return <WiRainMix style={{ fontSize: "45px", color: "#555555" }} />;
      case "heavy intensity shower rain":
        return <WiRain style={{ fontSize: "45px", color: "#555555" }} />;
      case "ragged shower rain":
        return <WiRain style={{ fontSize: "45px", color: "#555555" }} />;

      case "light snow":
        return <WiSnow style={{ fontSize: "45px", color: "white" }} />;
      case "snow":
        return <WiSnow style={{ fontSize: "45px", color: "white" }} />;
      case "heavy snow":
        return <WiSnow style={{ fontSize: "45px", color: "white" }} />;
      case "sleet":
        return <WiSleet style={{ fontSize: "45px", color: "white" }} />;
      case "light shower sleet":
        return <WiSleet style={{ fontSize: "45px", color: "white" }} />;
      case "shower sleet":
        return <WiSleet style={{ fontSize: "45px", color: "white" }} />;
      case "light rain and snow":
        return <WiSleet style={{ fontSize: "45px", color: "white" }} />;
      case "rain and snow":
        return <WiSleet style={{ fontSize: "45px", color: "white" }} />;
      case "light shower snow":
        return <WiSnow style={{ fontSize: "45px", color: "white" }} />;
      case "shower snow":
        return <WiSnow style={{ fontSize: "45px", color: "white" }} />;
      case "heavy shower snow":
        return <WiSnow style={{ fontSize: "45px", color: "white" }} />;

      case "fog":
        return <WiFog style={{ fontSize: "45px", color: "#555555" }} />;
      case "mist":
        return <WiFog style={{ fontSize: "45px", color: "#555555" }} />;
      case "haze":
        return <WiFog style={{ fontSize: "45px", color: "#555555" }} />;
      case "smoke":
        return <WiFog style={{ fontSize: "45px", color: "#555555" }} />;
      case "sand/dust whirls":
        return <WiFog style={{ fontSize: "45px", color: "#555555" }} />;
      case "sand":
        return <WiFog style={{ fontSize: "45px", color: "#555555" }} />;
      case "dust":
        return <WiFog style={{ fontSize: "45px", color: "#555555" }} />;
      case "volcanic ash":
        return <WiFog style={{ fontSize: "45px", color: "#555555" }} />;
      case "squalls":
        return <WiFog style={{ fontSize: "45px", color: "#555555" }} />;
      case "tornado":
        return <WiFog style={{ fontSize: "45px", color: "#555555" }} />;
      default:
        return <WiRainMix style={{ fontSize: "45px", color: "#555555" }} />;
    }
  };

  const getBackgroundColorForInnerCard = (period, weatherCode, timestampStr, sunrise, sunset, 
    timezoneOffset, weatherDescription, index) => {
   const sunriseDate = new Date((sunrise + timezoneOffset) * 1000);
    const sunsetDate = new Date((sunset + timezoneOffset) * 1000);
    const currentDate = new Date(new Date(timestampStr.replace(/-/g, "/")).getTime() + timezoneOffset * 1000);

  
    let isDayTime;
    if (period === 'Morning' && sunriseDate.getUTCHours() >= 10) {
      isDayTime = false;
    }
    else if (period === 'Morning' && sunriseDate.getUTCHours() <=10) {
        isDayTime = true;
        
    } 
    else if (period === 'Evening' && sunsetDate.getUTCHours() >= 21 ) {
      isDayTime = true;
    }
    else if (period === 'Evening' && sunsetDate.getUTCHours() <= 21) {
      isDayTime = false;
    }
    else {
      const currentHour = currentDate.getHours();
      const sunriseHour = sunriseDate.getUTCHours();
      const sunsetHour = sunsetDate.getUTCHours();
      isDayTime = currentHour >= sunriseHour && currentHour < sunsetHour;
    }

    switch (weatherCode) {
      case "Clear":
        if (isDayTime) {
          return "linear-gradient(to bottom, var(--clear), var(--clear))";
        } else {
          return "linear-gradient(to bottom, var(--night1), var(--tertiary))";
        }
      case "Clouds":
        if (isDayTime) {
          if (weatherDescription === 'few clouds'){
            return "linear-gradient(to bottom, var(--clear), var(--clouds))"
          }
          return "linear-gradient(to bottom, var(--clouds), var(--clouds))";
        } else {
          return "linear-gradient(to bottom, var(--night1), var(--clouds))";
        }
      case "Rain":
        if (isDayTime) {
          return "linear-gradient(to bottom, var(--rain), var(--rain))";
        } else {
          return "linear-gradient(to bottom, var(--night1), var(--rain))";
        }
      case "Drizzle":
        if (isDayTime) {
            return "linear-gradient(to bottom, var(--rain), var(--rain))";
          } else {
            return "linear-gradient(to bottom, var(--night1), var(--rain))";
      }
      case "Snow":
        if (isDayTime) {
          return "linear-gradient(to bottom, var(--snow), var(--snow))";
        } else {
          return "linear-gradient(to bottom, var(--night1), var(--snow))";
        }
      case "Haze":
        if (isDayTime) {
          return "linear-gradient(to bottom, var(--snow), var(--snow))";
        } else {
          return "linear-gradient(to bottom, var(--night1), var(--snow))";
        }
      default:
        if (!isDayTime) {
          return "linear-gradient(to bottom, var(--night1), var(--clouds))";
          } else {
          return "linear-gradient(to bottom, var(--clouds), var(--clouds))";
        }
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 440);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 440);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

  return (
    <Paper
      elevation={3}
      style={{
        backgroundColor: 'white',
        marginBottom: isMobile ? '8px' : '16px',
        borderRadius: "20px",
        boxShadow: "0px 4px 12px rgba(0,0,0,0.6)",
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >

      <CardContent
        style={{
          position: "relative",
          width: '100%'

        }}
      >

        <div
          style={{
            maxWidth: "100%", // Use percentage for responsiveness
            display: "flex",
            // flexWrap: 'wrap', // Allow items to wrap
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: '-10px'
          }}
        >
          <Typography
            variant={isMobile ? 'h7' : 'h6'}
            component="div"
            style={{
              fontWeight: "bold",
              marginBottom: "8px",
              marginLeft: '16px',
              textAlign: "left",
              color: "var(--secondary)",
              paddingRight: "10px",

            }}
          >
            What to Expect
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: "5px",
            marginTop: "-8px",
            width: '100%',
            marginBottom: isMobile ? '-22px' : '-8px'
            
          }}>

          {Object.entries(periods).map(([period, data], index) => (
            <Paper
              key={period} 
              elevation={1}
              style={{
                backgroundImage: getBackgroundColorForInnerCard(period, data.main, data.timestamp, data.sunrise, data.sunset, data.timezone, data.description, index),
                flexDirection: 'column', 
                alignItems: 'center', 
                borderRadius: "15px",
                boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
                flexGrow: 1, 
                maxWidth: '160px',
                minWidth: '75px', 
                marginLeft: '2  %',
                marginRight: '3%'
              }}

            >
              <div
                key={period}
                style={{
                  flex: 1,
                  margin: '8px',
                  textAlign: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Typography
                  // variant={isMobile ? "subtitle3" : "subtitle1"}
                  style={{
                    marginTop: isMobile ? '1px' : '4px',
                    marginBottom: isMobile ? '0px' : '4px',
                    textAlign: "center",
                    fontWeight: "bold",
                    color: 'white',
                    fontSize: 'clamp(14px, 1.5vw, 22px)'
                  }}>
                  {period}
                </Typography>


                <ListItemIcon
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    marginTop: isMobile ? '1px' : '4px',
                    marginBottom: isMobile ? '1px' : '4px',
                  }}
                >
                  {data.description ?
                    <div key={period}
                      style={{ fontSize: isMobile ? '20px' : '30px' }}>
                      {getWeatherIcon(period, data.description, data.timestamp, data.sunrise, data.sunset, data.timezone, index)}
                    </div> : ''}
                </ListItemIcon>


                {data.temp ?
                  <Typography
                    style={{
                      marginBottom: isMobile ? '1px' : '4px',
                      fontSize: isMobile ? '18px ': '20px',
                      color: "var(--maxtemp)",
                      fontWeight: "bold",
                    }}
                  >  {kelvinToUnit(data.temp)}°</Typography> : '--'}

              </div>
            </Paper>
          ))}
        </div>
      </CardContent>
    </Paper>
  );
}

export default TodayPeriodWeatherCard
