import React, {lazy, useState, useEffect, Suspense } from "react";
import "../../App.css";
import {
  Paper,
  CardContent,
  Typography,
  ListItemIcon,
  Divider,
  
} from "@mui/material";
import AirIcon from "@mui/icons-material/Air";
import OpacityIcon from "@mui/icons-material/Opacity";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  WiDaySunny,
  
  WiCloud,
  WiDayCloudy,
  WiCloudy,
  WiRainMix,
  WiRain,
  WiThunderstorm,
  WiFog,
  WiSleet,
  WiSnowflakeCold
 } from 'weather-icons-react';
 import { useTemperatureUnit } from '../../TemperatureUnitContext';


 const TwoWeekForecastCardContents = lazy(() => import('./contents/twoWeekForecastCardContents.js'));

function TwoWeekForecastCard({forecastData, currentWeather}) {
  const [expanded, setExpanded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { unit } = useTemperatureUnit();
  
  const kelvinToUnit = (kelvin) => {
    if(unit === 'degC') {
      return Math.round((kelvin - 273.15).toFixed(2));
    } 
    return Math.round(((kelvin - 273.15) * 9/5 + 32).toFixed(2)); 
  };

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const getDayAndDate = (index, timezoneOffsetSeconds) => {
    if (index === 0) {
      return "Today";
    }
  
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const today = new Date();
    
    // Adjust for timezone offset
    const targetDate = new Date(
      today.getTime() + timezoneOffsetSeconds * 1000 + index * 24 * 60 * 60 * 1000
    );
  
    const dayName = daysOfWeek[targetDate.getDay()];
    const dateStr = targetDate.toLocaleDateString("en-GB", { day: "numeric" });
  
    return `${dayName} ${dateStr}`;
  };
  
  // pop is chance of precipitation
  const getWeatherIcon = (weatherCode, card, pop) => {
    switch (weatherCode) {
      case "sky is clear":
        if (card){
          return  <WiDaySunny  alt="sunny" style={{ fontSize: isMobile ? '45px' : '45px', color: "yellow" }} />;
        } else{
          return <WiDaySunny alt="sunny" style={{ fontSize: isMobile ? '45px' : '45px', color: "hsl(48, 110%, 50%)" }} />;
        }
      case "few clouds":
        return <img src="/cloud_sun.ico" alt="Day Sunny Overcast" style={{ width: "40px", height: "38px", padding: "3px 0px 1px 4px", }} />
      case "scattered clouds":
        return <WiCloud  alt="Cloudy" style={{ fontSize:"45px", color: "var(--altcloud)" }} />;
      case "broken clouds":
        return <WiCloudy alt="Cloudy"  style={{ fontSize:"45px", color: "var(--altcloud)" }} />;
      case "overcast clouds":
        return <WiCloudy alt="Cloudy" style={{ fontSize:"45px", color: "var(--altcloud)" }} />;
        
      case "thunderstorm with light rain":
        return <WiThunderstorm alt="Thunderstorms" style={{ fontSize:"45px", color: "white" }} />;
      case "thunderstorm with rain":
        return <WiThunderstorm alt="Thunderstorms" style={{ fontSize:"45px", color: "white" }} />;
      case "thunderstorm with heavy rain":
        return <WiThunderstorm alt="Thunderstorms" style={{ fontSize:"45px", color: "white" }} />;
      case "light thunderstorm":
        return <WiThunderstorm alt="Thunderstorms" style={{ fontSize:"45px", color: "white" }} />;
      case "thunderstorm":
        return <WiThunderstorm alt="Thunderstorms" style={{ fontSize:"45px", color: "white" }} />;
      case "heavy thunderstorm":
        return <WiThunderstorm alt="Thunderstorms" style={{ fontSize:"45px", color: "white" }} />;
      case "ragged thunderstorm":
        return <WiThunderstorm alt="Thunderstorms"  style={{ fontSize:"45px", color: "white" }} />;
      case "thunderstorm with light drizzle":
        return <WiThunderstorm alt="Thunderstorms"  style={{ fontSize:"45px", color: "white" }} />;
      case "thunderstorm with drizzle":
        return <WiThunderstorm alt="Thunderstorms"  style={{ fontSize:"45px", color: "white" }} />;
      case "thunderstorm with heavy drizzle":
        return <WiThunderstorm alt="Thunderstorms"  style={{ fontSize:"45px", color: "white" }} />;

      case "light rain":
        if(pop < 0.5){
          return <WiCloudy alt="Cloudy"  style={{ fontSize:"45px", color: "var(--altcloud)" }} />;
        }else{
          return <WiRainMix alt="Rain" style={{ fontSize:"45px", color: "#555555" }} />;
        }
      case "moderate rain":
        if(pop < 0.5){
          return <WiCloudy alt="Cloudy"  style={{ fontSize:"45px", color: "var(--altcloud)" }} />;
        }else{
        return <WiRainMix alt="Rain" style={{ fontSize:"45px", color: "#555555" }} />;
        }
      case "heavy intensity rain":
        return <WiRain alt="Rain" style={{ fontSize:"45px", color: "#555555" }} />;
      case "very heavy rain":
        return <WiRain alt="Rain" style={{ fontSize:"45px", color: "#555555" }} />;
      case "extreme rain":
        return <WiRain alt="Rain" style={{ fontSize:"45px", color: "#555555" }} />;
      case "freezing rain":
        return <WiSleet alt="Rain" style={{ fontSize:"45px", color: "#555555" }} />;
      case "light intensity shower rain":
        if(pop < 0.5){
          return <WiCloudy alt="Cloudy"  style={{ fontSize:"45px", color: "var(--altcloud)" }} />;
        }else{
        return <WiRainMix alt="Rain" style={{ fontSize:"45px", color: "#555555" }} />;
        }
      case "shower rain":
        return <WiRainMix alt="Rain" style={{ fontSize:"45px", color: "#555555" }} />;
      case "heavy intensity shower rain":
        return <WiRain alt="Rain" style={{ fontSize:"45px", color: "#555555" }} />;
      case "ragged shower rain":
        return <WiRain alt="Rain" style={{ fontSize:"45px", color: "#555555" }} />;
        
      case "light snow":
        return <WiSnowflakeCold alt="Snow" style={{ fontSize:"45px", color: "#555555" }} />;
      case "snow":
        return <WiSnowflakeCold alt="Snow" style={{ fontSize:"45px", color: "#555555" }} />;
      case "heavy snow":
        return <WiSnowflakeCold alt="Snow" style={{ fontSize:"45px", color: "#555555" }} />;
      case "sleet":
        return <WiSnowflakeCold alt="Snow" style={{ fontSize:"45px", color: "#555555" }} />;
      case "light shower sleet":
        return <WiSleet alt="Snow" style={{ fontSize:"45px", color: "#555555" }} />;
      case "shower sleet":
        return <WiSleet alt="Snow" style={{ fontSize:"45px", color: "#555555" }} />;
      case "light rain and snow":
        return <WiSleet alt="Snow" style={{ fontSize:"45px", color: "#555555" }} />;
      case "rain and snow":
        return <WiSleet alt="Snow" style={{ fontSize:"45px", color: "#555555" }} />;
      case "light shower snow":
        return <WiSnowflakeCold alt="Snow" style={{ fontSize:"45px", color: "#555555" }} />;
      case "shower snow":
        return <WiSnowflakeCold alt="Snow" style={{ fontSize:"45px", color: "#555555" }} />;
      case "heavy shower snow":
        return <WiSnowflakeCold alt="Snow" style={{ fontSize:"45px", color: "#555555" }} />;

      case "fog":
        return <WiFog alt="fog" style={{ fontSize:"45px", color: "#555555" }} />;
      case "mist":
        return <WiFog alt="fog" style={{ fontSize:"45px", color: "#555555" }} />;
      case "haze":
        return <WiFog alt="fog" style={{ fontSize:"45px", color: "#555555" }} />;
      case "smoke":
        return <WiFog alt="fog" style={{ fontSize:"45px", color: "#555555" }} />;
      case "sand/dust whirls":
        return <WiFog alt="fog" style={{ fontSize:"45px", color: "#555555" }} />;
      case "sand":
        return <WiFog alt="fog" style={{ fontSize:"45px", color: "#555555" }} />;
      case "dust":
        return <WiFog alt="fog" style={{ fontSize:"45px", color: "#555555" }} />;
      case "volcanic ash":
        return <WiFog alt="fog" style={{ fontSize:"45px", color: "#555555" }} />;
      case "squalls":
        return <WiFog alt="fog" style={{ fontSize:"45px", color: "#555555" }} />;
      case "tornado":
        return <WiFog alt="fog" style={{ fontSize:"45px", color: "#555555" }} />;
      default:
        return <WiRainMix alt="rain" style={{ fontSize:"45px", color: "#555555" }} />;
    }
  };

  const getBackgroundColor = (weatherCode) => {
    switch (weatherCode) {
      case "Clear":
        return  "linear-gradient(to bottom, var(--secondary), var(--tertiary))";
      case "Clouds":
        return "linear-gradient(to bottom, var(--2), var(--3))";
      case "Rain":
        return "linear-gradient(to bottom, var(--1), var(--2))";
      case "Snow":
        return "linear-gradient(to bottom, var(--1), var(--2))";
      default:
        return "linear-gradient(to bottom, var(--clouds), var(--clouds))";
    }
  };

  const getBackgroundColorForInnerCard = (weatherCode, weatherDescription, pop) => {

    switch (weatherCode) {
      case "Clear":
        return "linear-gradient(to bottom, var(--clear), var(--clear))";
      case "Clouds":
        if (weatherDescription === 'few clouds'){
          return "linear-gradient(to bottom, var(--clear), var(--clouds))";
        }
        return "linear-gradient(to bottom, var(--clouds), var(--clouds))";
      case "Rain":
        if(pop < 0.5){
        return "linear-gradient(to bottom, var(--clouds), var(--rain))";
        } else{
          return "linear-gradient(to bottom, var(--rain), var(--rain))";
        }
      case "Snow":
        return "linear-gradient(to bottom, var(--snow), var(--snow))";
      default:
        return "linear-gradient(to bottom, var(--clear), var(--clear))";
    }
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getItemsToShow = () => {
    if (windowWidth >= 700) return 7;
    if (windowWidth >= 650) return 7;
    if (windowWidth >= 580) return 6;
    if (windowWidth >= 550) return 5;
    if (windowWidth >= 500) return 4;
    return 4; // mobile
  };


  const getWindDirection = (degrees) => {
    if (degrees >= 0 && degrees <= 22.5) return "N↓";
    if (degrees > 22.5 && degrees <= 67.5) return "NE↙";
    if (degrees > 67.5 && degrees <= 112.5) return "E←";
    if (degrees > 112.5 && degrees <= 157.5) return "SE↖";
    if (degrees > 157.5 && degrees <= 202.5) return "S↑";
    if (degrees > 202.5 && degrees <= 247.5) return "SW↗";
    if (degrees > 247.5 && degrees <= 292.5) return "W→";
    if (degrees > 292.5 && degrees <= 337.5) return "NW↘";
    return "N"; // Default to North for degrees >= 337.5 and for any unexpected values
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 440);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 440);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
      
        <Paper
          className="weather-card"
          elevation={3}
          style={{
            marginBottom: isMobile ? '8px' : '16px',
            borderRadius: "20px",
            boxShadow: "0px 4px 12px rgba(0,0,0,0.6)",
            height: !expanded ? (isMobile ? '167px' : '199px') : 'auto'
          }}
          onClick={handleToggleExpand}
        >

          {(forecastData && currentWeather) && (
            <Suspense>
                <TwoWeekForecastCardContents
                    forecastData={forecastData} currentWeather={currentWeather} isMobile={isMobile} getBackgroundColor={getBackgroundColor}
                    getBackgroundColorForInnerCard={getBackgroundColorForInnerCard} getDayAndDate={getDayAndDate} getWeatherIcon={getWeatherIcon}
                    kelvinToUnit={kelvinToUnit} getWindDirection={getWindDirection} getItemsToShow={getItemsToShow} expanded={expanded}
                     handleToggleExpand={handleToggleExpand} >
                </TwoWeekForecastCardContents>
            </Suspense> 
          )}
          
        </Paper>
      
    )
}

export default TwoWeekForecastCard;
