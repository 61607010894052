import React, { createContext, useCallback, useContext, useState } from 'react';

// Create a context with a default value
const TemperatureUnitContext = createContext();

// Custom hook for easy access to the context
export const useTemperatureUnit = () => useContext(TemperatureUnitContext);

export const TemperatureUnitProvider = ({ children }) => {
  const [unit, setUnit] = useState('degC'); // Default to Celsius

  // Function to toggle the unit
  const toggleUnit = useCallback((value) => {
    setUnit(value);
  },[]);

  return (
    <TemperatureUnitContext.Provider value={{ unit, toggleUnit }}>
      {children}
    </TemperatureUnitContext.Provider>
  );
};
