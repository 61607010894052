import React, { useState, useEffect } from 'react';
import {Paper, Grid, Typography, Divider} from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTemperatureUnit } from '../TemperatureUnitContext.js';
import { kelvinToUnit } from '../sunradar/customHooks.js';

function ExtraInfo({ extraInfo }) {
  const { unit } = useTemperatureUnit();

  const abbreviateMonth = (month) => {
    const monthAbbr = {
      January: 'Jan',
      February: 'Feb',
      March: 'Mar',
      April: 'Apr',
      May: 'May',
      June: 'Jun',
      July: 'Jul',
      August: 'Aug',
      September: 'Sep',
      October: 'Oct',
      November: 'Nov',
      December: 'Dec'
    };
    return monthAbbr[month] || month;
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 440);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 440);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderExpenseRank = (currency, rank) => {
    const currencySymbol = currency.split(' ')[0] || '$'// Extract the currency symbol
    const fullSymbols = currencySymbol.repeat(rank);
    const emptySymbols = currencySymbol.repeat(5 - rank);
    
    return (
      <>
        <span> {fullSymbols}</span>
        <span style={{ color: 'rgba(0, 0, 0, 0.2)' }}>{emptySymbols}</span>
      </>
    );
  };

  const extractNumericValue = (tempString) => {
    return parseFloat(tempString.replace(/[^\d.-]/g, ''));
  };

  // Carousel settings
  const settings = {
    dots: isMobile ? false : true,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile ? 4.5 : 6,
    slidesToScroll: 3,
    arrows: isMobile ? false : true
  };

  return (
    <div>
      <Paper
        style={{
          borderRadius: '20px',
          padding: '15px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          margin: '20px 0',
        }}
      >
        {/* <Typography variant="h4" style={{color: 'grey'}} gutterBottom>
          {extraInfo.location}
        </Typography> */}
        <Typography variant= {isMobile ? 'body2' : "body1"} paragraph>
          {extraInfo.abstract.main_points}
        </Typography>
        

        <Paper
      style={{
        backgroundColor: '#ECECEC',
        borderRadius: isMobile ? '10px' : '15px',
        padding: '10px 0px',
        marginBottom: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Grid container alignItems="center" style={{ display: 'flex', flexWrap: 'nowrap' }}>
        <Grid item xs={3} style={{ flexBasis: '25%' }}>
          <Typography variant={isMobile ? "subtitle2" : "h6"} align="center">Best Month</Typography>
          <Typography variant={isMobile ? "body2" : "body1"} style={{ color: 'grey' }} align="center">{extraInfo.best_time_to_visit}</Typography>
        </Grid>
        <Divider orientation="vertical" flexItem style={{ backgroundColor: 'lightgrey', height: isMobile ? '40px' : '55px' }} />
        <Grid item xs={3} style={{ flexBasis: '25%' }}>
          <Typography variant={isMobile ? "subtitle2" : "h6"} align="center">Currency</Typography>
          <Typography variant={isMobile ? "body2" : "body1"} style={{ color: 'grey' }} align="center">{extraInfo.currency}</Typography>
        </Grid>
        <Divider orientation="vertical" flexItem style={{ backgroundColor: 'lightgrey', height: isMobile ? '40px' : '55px' }} />
        <Grid item xs={3} style={{ flexBasis: '25%' }}>
          <Typography variant={isMobile ? "subtitle2" : "h6"} align="center">Airport</Typography>
          <Typography variant={isMobile ? "body2" : "body1"} style={{ color: 'grey' }} align="center">
            {extraInfo.closest_main_airport.match(/\(([^)]+)\)/)?.[1]}
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem style={{ backgroundColor: 'lightgrey', height: isMobile ? '40px' : '55px' }} />
        <Grid item xs={3} style={{ flexBasis: '25%' }}>
          <Typography variant={isMobile ? "subtitle2" : "h6"} align="center">Expense</Typography>
          <Typography variant={isMobile ? "body2" : "body1"} align="center">{renderExpenseRank(extraInfo.currency, extraInfo.expense_rank)}</Typography>
        </Grid>
      </Grid>
    </Paper>

        <Typography variant={isMobile ? 'body2' : "body1"} paragraph>
          {extraInfo.abstract.cultural_significance}
        </Typography>

        <Typography variant={isMobile ? 'subtitle1' : "h6"} style={{ marginTop: '16px' }}>
          Average Temperature by Month
        </Typography>
        <Slider {...settings}>
          {Object.entries(extraInfo.average_temperature_by_month).map(([month, temp]) => (
            <div key={month}>
              <Paper
                style={{
                  padding: '10px',
                  textAlign: 'center',
                  backgroundColor: '#ECECEC',
                  borderRadius: '10px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  margin: '10px',
                }}
              >
                <Typography variant={isMobile ? 'body2' : "body1"} fontWeight="bold">{abbreviateMonth(month)}</Typography>
                <Typography variant="body2">{kelvinToUnit(unit, extractNumericValue(temp))}°</Typography>
              </Paper>
            </div>
          ))}
        </Slider>

        <Typography variant={isMobile ? 'subtitle1' : "h6"} style={{ marginTop: '16px' }}>
          Best Things to Do
        </Typography>
        {isMobile ? (
        <ul style={{ paddingLeft: '6px', listStyleType: 'none' }}>
          {extraInfo.best_things_to_do.map((activity, index) => (
            <li key={index}>
              <Typography variant={isMobile ? 'body2' : "body1"}>{activity}</Typography>
            </li>
          ))}
        </ul>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
              {extraInfo.best_things_to_do.slice(0, 3).map((activity, index) => (
                <li key={index}>
                  <Typography variant="body1">{activity}</Typography>
                </li>
              ))}
            </ul>
          </Grid>
          <Divider orientation="vertical" flexItem style={{ backgroundColor: 'lightgrey', height: 'auto', marginTop: '25px', marginBottom: '10px' }} />
          <Grid item xs={5}>
            <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
              {extraInfo.best_things_to_do.slice(3).map((activity, index) => (
                <li key={index}>
                  <Typography variant="body1">{activity}</Typography>
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
      )}
      </Paper>
    </div>
  );
}

export default ExtraInfo;
