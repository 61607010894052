import React, { useState, useEffect } from 'react';
import { Typography, Paper } from '@mui/material';

function Tagline() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 440);

    useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth <= 440);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
      // Set a timer to hide the Tagline component after 30 seconds
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 30000); // 30000 milliseconds = 30 seconds
  
      // Cleanup function to clear the timer if the component unmounts before the timer finishes
      return () => clearTimeout(timer);
    }, []); // The empty dependency array ensures this effect runs only once after the initial render
  
    // Return nothing if isVisible is false
    if (!isVisible) {
      return null;
    }

        return (
            <Paper style={{ borderRadius: '20px', padding: '10px', marginBottom: isMobile ? '4px' : '16px' }}> {/* Add padding to the Paper component */}
                <div style={{ textAlign: 'center' }}> {/* Center the content */}
                    <Typography
                      variant={isMobile ? 'h7' : 'h5'} // Adjust the variant based on the isMobile flag
                      style={{ color: 'gray', padding: '10px' }} // Centralized styling for Typography
                    >
                        1000+ holiday destinations, ranked by sunshine. So you always know where it's sunny!
                    </Typography>
                </div>
            </Paper>
        );
}

export default Tagline
